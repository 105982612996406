import React from 'react'
import Heading from './Heading'

//TODO: finish section

const embeddable = ({ data }) => {
    const {
        sectionId = '',
        embedButton,
        backlink,
        bottom,
        embedInstructions,
        fieldGroupName,
        image,
        imageSrc,
        imageType,
        subtitle,
        text,
        title,
    } = data

    const imageSource = imageType === 'external' ? imageSrc : image.sourceUrl

    return (
        <section className={`embeddable ${text && 'embeddable--img-only'}`}>
            {title || subtitle && (
                <Heading title={title} subtitle={subtitle} />
            )}

            <div className='embeddable__text' dangerouslySetInnerHTML={{ __html: text }} />

            <div className="embeddable__image">
                <div className="embeddable__image-cont embeddable__image-cont--parallax">
                    <img src={imageSource} alt={title} />
                </div>
            </div>

            <div className='embeddable__code'>
                <div className='embeddable__code__instructions' dangerouslySetInnerHTML={{ __html: embedInstructions }} /> 
            </div>
            
            <div className='cta embeddable__code__button'>
                <div className='cta__slant'/>
            </div>

        </section>
    )
}

export default embeddable