import React from 'react'
import Heading from './Heading'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

const intro = ({ data, pageContext, pageTitle }) => {
    const {
        introLogo,
        introImage,
        introText,
        introTitle,
        introSubtitle
    } = data

    const { breadcrumb } = pageContext;
    const crumbs = breadcrumb.crumbs.map(crumb => {
        return {
            pathname: crumb.pathname,
            crumbLabel: crumb.crumbLabel.replace('-', ' ')
        }
    })

    //TODO: style breadcrumbs

    return (
        <section className='intro-pro'>
            {/* <div className='breadcrumbs__wrapper'>
                <Breadcrumb crumbs={crumbs} crumbLabel={pageTitle} crumbSeparator='›' />
            </div> */}

            <div className='intro-pro__heading'>
                <div className='intro-pro__logo'>
                    <img src={introLogo} alt="audibene pro logo" />
                </div>
                <Heading title={introTitle} subtitle={introSubtitle} />
            </div>

            <div className='intro-pro__image'>
                {introImage && (
                    <img src={introImage.sourceUrl} alt={introImage.altText} />
                )}
            </div>

            <div className='intro-pro__content'>
                <div className='intro-pro__main-text' dangerouslySetInnerHTML={{ __html: introText }} />
            </div>
            
            <div className='separator'></div>
            
        </section>
    )
}

export default intro