import React from 'react'
import Heading from './Heading'

const about = ({ data }) => {
    const {
        aboutHearingTitle,
        aboutHearingText,
        aboutHearingImage
    } = data

    return (
        <section className='about'>
            <div className='about__heading'>
                <Heading title={aboutHearingTitle} />
            </div>

            <div className='about__wrapper'>
                <div className='about__image'>
                    <div className='about-image-cont'>
                        <img loading='lazy' src={aboutHearingImage.sourceUrl} alt={aboutHearingImage.altText} />
                    </div>
                </div>
                <div className='about__text' dangerouslySetInnerHTML={{ __html: aboutHearingText }} />
            </div>
        </section>
    )
}

export default about