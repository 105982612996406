import { smoothScrollTo } from '../utils'

export const xModels = () => {
    // Slide change
    const handleSlideChange = (slideIndex, selectors) => {
        document.querySelectorAll('.x-models .active').forEach(el => el.classList.remove('active'))
        document.querySelector(`.x-models__model--${slideIndex}`).classList.add('active');
        
        selectors.forEach(selector => {
            selector !== '.x-models__arrow' && document.querySelectorAll(`${selector}--${slideIndex}`).forEach(el => el.classList.add('active'))
        })
    }

    const listenSlideChange = (selectors) => {
        selectors.forEach(selector => {
            document.querySelectorAll(selector) && document.querySelectorAll(selector).forEach(btn => {
                btn.addEventListener('click', () => {
                    const slideIndex = btn.dataset.slide;
                    handleSlideChange(slideIndex, selectors);
                })
            })
        })
    }

    listenSlideChange(['.x-models__button', '.x-models__bottom-button', '.x-models__nav-dot', '.x-models__arrow']);

    // color Change
    document.querySelectorAll('.x-models__model') && document.querySelectorAll('.x-models__model').forEach(slide => {
        const colorGroups = slide.querySelectorAll('.x-models__color-selector');
        colorGroups && colorGroups.forEach(colorGroup => {
            const colors = colorGroup.querySelectorAll('.x-models__color-icon');
            colors.forEach(color => {
                color.addEventListener('click', () => {
                    const colorIndex = color.dataset.color;
                    slide.querySelectorAll('.active-color').forEach(el => el.classList.remove('active-color'))
                    slide.querySelector(`.x-models__color-image--${colorIndex}`).classList.add('active-color');
                    color.classList.add('active-color');
                })
            })
        });
    })

    // scroll into view
    const xModelsButtons = document.querySelector('.x-models__buttons');
    document.querySelectorAll('.x-models__bottom-button').forEach(btn => btn.addEventListener('click', () => smoothScrollTo(xModelsButtons)));
}