export const soundCards = () => {
	const playerHandler = (playButton) => {
		playButton.classList.contains('sound-card__play-button--active') &&  playButton.classList.remove('sound-card__play-button--active'); 
	}
	
	const stopAllSounds = () => {
		
		document.querySelectorAll('audio').forEach(audio => {
			audio.pause();
		})
		document.querySelectorAll('.sound-card__play-button').forEach(play => {
			const parentContainer = play.closest('.sound-card__body');
			
			parentContainer.querySelector('.sound-card__play-button--active')?.classList.remove('sound-card__play-button--active');
	
			parentContainer.classList.remove('play')
		})
	}

	const handlePlay = btn => {
		const parentContainer = btn.closest('.sound-card__body');
		const sound = parentContainer.querySelector('audio');
		const bar = btn.parentElement.querySelector('.sound-card__bar');
	
		sound.addEventListener('timeupdate', () => {
			let progress = sound.currentTime / sound.duration;
			bar.value = progress;
		})
		
		sound.addEventListener('ended', () => {
			parentContainer.classList.add('replay')
			parentContainer.classList.remove('play')
	
			btn.classList.remove('sound-card__play-button--active')
		})
	
		btn.addEventListener('click', () => {
			parentContainer.dataset.ready = 'true';
			
			if (btn.classList.contains('sound-card__play-button--active')) {
				sound.pause();
			} else {
				btn.parentElement.querySelector('progress').style.opacity = '';
				stopAllSounds();
				sound.play();
			}
			btn.classList.toggle('sound-card__play-button--active');
			
			parentContainer.classList.toggle('play');
			parentContainer.classList.contains('replay') && parentContainer.classList.remove('replay')
		})
	}
	
	document.querySelectorAll('.sound-card__play-button').forEach(btn => handlePlay(btn))
}