import React from 'react'
import { Helmet } from 'react-helmet'

const Head = ({seo, data}) => {
    const isBrowser = () => typeof window !== "undefined"
    const { country, favicon, tracking: {cookiePlatformId, sessionLayerId}, scripts: {pushengageId, customScripts} } = data;
    const locale = country.replace('-', '_');

    return (
        <Helmet>
            <title>{seo.title}</title>
            <meta name='description' content={seo.metaDesc} />

            <meta property='og:locale' content={locale} />
            <meta property='og:type' content='website' />
            <meta property='og:title' content={seo.title} />
            <meta property='og:description' content={seo.metaDesc}></meta>
            <meta name='icon' href={favicon} />

            {cookiePlatformId && (
                <script type="application/javascript" src="https://app.usercentrics.eu/latest/main.js" id={cookiePlatformId}></script>
            )}

            <script src='https://session.cdn.audibene.net/sessionLayer.js' id='SessionLayer' data-locale={locale} data-gtmid={sessionLayerId}></script>
            
            {pushengageId && <script>window._peq = window._peq || []; window._peq.push(["init"]);</script>}
            {pushengageId && <script src={`https://clientcdn.pushengage.com/core/${pushengageId}.js`} async></script>}
            <script src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' async></script>
        </Helmet>
    )
}

export default Head