import React from 'react'
import Heading from '../Heading'

import playIcon from '../../../assets/images/horizon/sound/play.png'
import pauseIcon from '../../../assets/images/horizon/sound/pause.png'

const soundCard = (props) => {
    const {
        title,
        image,
        copyTitle,
        copyText,
        soundDefault,
        soundProcessed,
        effect,
        brandVariation,
        tooltip,
        tooltipEffect,
        simulatorOn,
        simulatorOff
    } = props

    return (
        <div className={`sound-simulator__card sound-simulator__card--${effect}`} data-effect={effect} >
            <div className='card__title-wrapper'>
                <div className='card__title'>
                    <Heading title={title} />
                </div>
            </div>
            <div className='card__audio-controls'>
                {brandVariation === 'b2b' ? (
                    <div className='card__image'>
                        <img loading='lazy' src={image.sourceUrl} alt={image.altText} />
                    </div>
                ) : (
                    <>
                    <div className='card__image'>
                        <img loading='lazy' src={image.sourceUrl} alt={image.altText} />
                        <div className='card__audio-player'>
                            <audio controls preload='none' className='soundUntouched'>
                                <source src={soundDefault.mediaItemUrl} type='audio/mpeg' />
                            </audio>
                    
                            <audio controls preload='none' className='soundProcessed'>
                                <source src={soundProcessed.mediaItemUrl} type='audio/mpeg' />
                            </audio>
                            <button className='card__play-button'>
                                <img className='card__play-icon' src={playIcon} />
                                <img className='card__pause-icon' src={pauseIcon} />
                            </button>
                            <div className='card__tooltip'><span>{tooltip}</span></div>
                            <progress value='0' max='1' className='card__bar' style={{opacity: 0}} />
                        </div>

                    </div>
                    <div className='card__effect-box'>
                        <label className='effect-box__io-button'>
                            <input type="checkbox" disabled />
                            <div className={`io-button__toggle io-button__toggle--${effect}`} data-effect={effect} >
                                <small className='io-button__on-off io-button__on-off--off'>{simulatorOff}</small>
                                <span className='io-button__toggle-gray'></span>
                                <span className='io-button__toggle-blue'></span>
                                <small className='io-button__on-off io-button__on-off--on'>{simulatorOn}</small>
                            </div>
                        </label>
                        <div className='card__tooltip'><span>{tooltipEffect}</span></div>
                    </div>
                    </>
                )}
            </div>
            <div className='card__copy'>
                <div className='card__copy-title' dangerouslySetInnerHTML={{ __html: copyTitle }} />
                <div className='card__copy-text' dangerouslySetInnerHTML={{ __html: copyText }} />
            </div>
        </div>
    )
}

export default soundCard