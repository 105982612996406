import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro.pro'
import ImageText from '../components/FlexibleContent/ImageText'
import Carousel from '../components/FlexibleContent/Carousel.pro'
import SplitText from '../components/FlexibleContent/SplitText'
import Showcase from '../components/FlexibleContent/Showcase'
import Cards from '../components/FlexibleContent/Cards'

const AudibenePro = ({ data, pageContext }) => {

  const { page, options } = data
  const {
    TPLAudibenePro,
    seo,
    template,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    footerSettings: { OPTFooterSettings },
    headerSettings: { OPTHeaderSettings }
  } = options

  const { proSections } = TPLAudibenePro

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'ImageText':
        return <ImageText data={data} template={template} key={index} />

      case 'Carousel':
        return <Carousel data={data} template={template} key={index} />

      case 'SplitText':
        return <SplitText data={data} key={index} />

      case 'Showcase':
        return <Showcase data={data} key={index} />

      case 'Cards':
        return <Cards data={data} key={index} />
      
      default:
        return <p key={index}>{contentType}</p>
    }
  }


  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-audibene-pro'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLAudibenePro} />
        <Intro data={TPLAudibenePro} pageContext={pageContext} pageTitle={title} />

        {proSections && proSections.map((section, index) => {
          const contentType = section.fieldGroupName.replace('page_Tplaudibenepro_ProSections_', '');
          return assignComponent(contentType, section, index);
        })}

        <Footer data={OPTFooterSettings} />

      </div>
      <Scripts />
    </>
  )
}

export default AudibenePro