export const navigationJs = () => {
/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */


( function() {
	var container, button, menu, links, i, len;

	container = document.getElementById( 'site-navigation' );
	if ( ! container ) {
		return;
	}

	button = container.getElementsByTagName( 'button' )[0];
	if ( 'undefined' === typeof button ) {
		return;
	}

	menu = container.getElementsByTagName( 'ul' )[0];

	// Hide menu toggle button if menu is empty and return early.
	if ( 'undefined' === typeof menu ) {
		button.style.display = 'none';
		return;
	}

	if ( -1 === menu.className.indexOf( 'nav-menu' ) ) {
		menu.className += ' nav-menu';
	}

	button.addEventListener('click', () => {
		
		if ( -1 !== container.className.indexOf( 'toggled' ) ) {
			container.className = container.className.replace( ' toggled', '' );
			document.querySelector('body').classList.remove('mobile-menu-open');
			
			document.querySelector('.nav-menu').classList.remove('nav-menu--scroll');
			button.setAttribute( 'aria-expanded', 'false' );
			closeSubs(0,true);
	
	
			document.style='overflow:hidden';
		} else {
			container.className += ' toggled';
			button.setAttribute( 'aria-expanded', 'true' );
			!document.querySelector('body').classList.contains('mobile-menu-open') && document.querySelector('body').classList.add('mobile-menu-open');
	
			!document.querySelector('.nav-menu').classList.contains('nav-menu--scroll') && document.querySelector('.nav-menu').classList.add('nav-menu--scroll');
	
			
		}
	})

	// // Close small menu when user clicks outside
	// document.addEventListener( 'click', function( event ) {
	// 	var isClickInside = container.contains( event.target );

	// 	if ( ! isClickInside ) {
	// 		container.className = container.className.replace( ' toggled', '' );
	// 		button.setAttribute( 'aria-expanded', 'false' );
	// 		closeSubs(0,true);
	// 	}
	// } );

	// Get all the link elements within the menu.
	links = menu.getElementsByTagName( 'a' );

	// Each time a menu link is focused or blurred, toggle focus.
	for ( i = 0, len = links.length; i < len; i++ ) {
		links[i].addEventListener( 'focus', toggleFocus, true );
		links[i].addEventListener( 'blur', toggleFocus, true );
	}

	/**
	 * Sets or removes .focus class on an element.
	 */
	function toggleFocus() {
		var self = this;

		// Move up through the ancestors of the current link until we hit .nav-menu.
		while ( -1 === self.className.indexOf( 'nav-menu' ) ) {
			// On li elements toggle the class .focus.
			if ( 'li' === self.tagName.toLowerCase() ) {
				if ( -1 !== self.className.indexOf( 'focus' ) ) {
					self.className = self.className.replace( ' focus', '' );
				} else {
					self.className += ' focus';
				}
			}

			self = self.parentElement;
		}
	}

	/**
	 * Toggles `focus` class to allow submenu access on tablets.
	 */
	( function() {
		var touchStartFn,
			parentLink = container.querySelectorAll( '.menu-item-has-children > a, .page_item_has_children > a' );

		if ( 'ontouchstart' in window ) {
			touchStartFn = function( e ) {
				var menuItem = this.parentNode;

				if ( ! menuItem.classList.contains( 'focus' ) ) {
					e.preventDefault();
					for ( i = 0; i < menuItem.parentNode.children.length; ++i ) {
						if ( menuItem === menuItem.parentNode.children[i] ) {
							continue;
						}
						menuItem.parentNode.children[i].classList.remove( 'focus');
					}
					!menuItem.classList.contains( 'focus' ) && menuItem.classList.add( 'focus' );
				} else {
					menuItem.classList.remove( 'focus' );
				}
			};

			for ( i = 0; i < parentLink.length; ++i ) {
				parentLink[i].addEventListener( 'touchstart', touchStartFn, false );
			}
		}
	}( container ) );
}() );

const nav = document.querySelector('#masthead');


//main menu items
const menuItems = document.querySelectorAll('.menu-item');


const goSticky = () => { 
	document.documentElement.scrollTop >= 20 ? nav.classList.add('header--sticky') : nav.classList.remove('header--sticky');

}
	
	
menuItems.forEach((menuItem) => {

	menuItem.addEventListener('click', () => {
		
		let level = menuItem.dataset.level;

		if(menuItem.classList.contains('menu-item-has-children')) {
			
			menuItem.classList.toggle('menu-item--expanded');
			if(level == 0) {
				
				const theSubMenu = document.querySelectorAll('.mega-menu');
				
				theSubMenu.forEach((submenu) => {
					
					if(submenu.classList.contains('mega-menu-'+menuItem.dataset.menuId)) {
						
						submenu.classList.toggle('menu--hide');
						
						if(submenu.classList.contains('menu--hide') == true) {
							closeSubs(level, false);
						}
						
					} else {
						!submenu.classList.contains('menu--hide') && submenu.classList.add('menu--hide');
						submenu.parentNode.querySelectorAll('.menu-item--expanded').forEach((otherMenuItem) => {
							if(otherMenuItem.dataset.menuId != menuItem.dataset.menuId) {
								otherMenuItem.classList.remove('menu-item--expanded');
							}
						})
					}

					submenu.querySelectorAll('.menu-item--level-1').forEach((menuLevel) => {
						const largeDisplay = window.matchMedia('(min-width: 992px)');
						if(largeDisplay.matches) {
							if (menuLevel.classList.contains('menu-item-has-children')) {
								menuLevel.style.display = 'none';
							} else {
								menuLevel.style.display = 'flex';
							}
						} else {
							menuLevel.style.display = 'flex';
						}

						!menuLevel.classList.contains('menu-item-has-children') && submenu.classList.add('mega-menu--single-level');
					})
				})
				
			} else if(level >= 1) {
				let nextLevel = parseInt(level, 10) + 1;
				const theSubMenu = document.querySelectorAll('.submenu--level-' + nextLevel);
				
				theSubMenu.forEach((submenu) => {
					
					if(submenu.classList.contains('submenu-'+menuItem.dataset.menuId)) {
						
						submenu.classList.toggle('menu--hide');
						
						submenu.classList.contains('menu--hide') && closeSubs(level,false);
						
					}else {
						!submenu.classList.contains('menu--hide') && submenu.classList.add('menu--hide');
						submenu.parentNode.querySelectorAll('.menu-item--expanded').forEach((otherMenuItem) => {
							if(otherMenuItem.dataset.menuId != menuItem.dataset.menuId) {
								otherMenuItem.classList.remove('menu-item--expanded');
							};
						})
					}
				})
			}
		}
	});

});


const closeSubs = (level, closeButton) => {

	for (let i = level; i < 3; i++) {
		
		if(closeButton) {
			document.querySelectorAll('.mega-menu').forEach((megamenu) => {
				!megamenu.classList.contains('menu--hide') && megamenu.classList.add('menu--hide');
			})
			document.querySelectorAll('.menu-item--level-0').forEach((subItem) => {
				subItem.classList.remove('menu-item--expanded');
			})
		}

		let index = i;
		index++;

		if(level == 0) {

			document.querySelectorAll('mega-menu').forEach((submenu) => {
				!submenu.classList.contains('menu--hide', true) && submenu.classList.add('menu--hide');
			})

			document.querySelectorAll('.menu-item--level-' + index).forEach((subItem) => {
				subItem.classList.remove('menu-item--expanded');
			})

			if(index != 1) {

				document.querySelectorAll('.submenu--level-' + index).forEach((submenu) => {
					if(submenu && !submenu.classList.contains('menu--hide')) {
						submenu.classList.add('menu--hide')
					};
				})

			} 

		} else if(level > 0) {

			document.querySelectorAll('.menu-item--level-' + index).forEach((subItem) => {
				subItem.classList.remove('menu-item--expanded');
			})

			 document.querySelectorAll('.submenu--level-'+index).forEach((submenu) => {
				if(submenu && !submenu.classList.contains('menu--hide')) {
					submenu.classList.add('menu--hide')
				};
			})

		}

		
	}
}

goSticky();

window.addEventListener('scroll', goSticky);

}