import React from 'react'

const Heading = ({ title, subtitle }) => {

    return (
        <div className='heading'>
            {title && (
                <h2 className='heading__title' dangerouslySetInnerHTML={{ __html: title }}/>
            )}
            {subtitle && (
                <p className='heading__subtitle' dangerouslySetInnerHTML={{ __html: subtitle }}/>
            )}
        </div>
    )
}

export default Heading