import React from 'react'
import Heading from '../Heading'
import Cta from '../Cta'

const multimedia = ({ data, ctaList }) => {
    const {
        brandVariation,
        multimediaBgDesktop,
        multimediaBgMobile,
        multimediaGridLeft,
        multimediaAppstoreLink,
        multimediaHeaderIcons,
        multimediaCopyTitle,
        multimediaCopyText,
        multimediaCopyList,
        multimediaCta
    } = data

    return (
        <section className='multimedia section-has-cta'>
            <div className='multimedia__image'>
                <picture>
                    <source srcSet={multimediaBgDesktop.sourceUrl}
                        alt={multimediaBgDesktop.altText} media='(min-width: 768px)' />
                    <img loading='lazy' src={multimediaBgMobile.sourceUrl}
                        alt={multimediaBgMobile.sourceUrl} />
                </picture>
            </div>
            <div className='multimedia__copy'>
                <div className='multimedia__heading'>
                    <div className='multimedia__heading-icons'>
                        {multimediaHeaderIcons?.map((icon, i) => (
                            <React.Fragment key={i}>
                                <img loading='lazy' className='multimedia__heading-icon' src={icon.multimediaHeaderIcon.sourceUrl} alt={icon.multimediaHeaderIcon.altText} />
                                <span className='multimedia__heading-icon-separator' />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                <div className='multimedia__content-wrapper'>
                    <div className='multimedia__title'>
                        <Heading title={multimediaCopyTitle} />
                    </div>
                    
                    <div className='multimedia__copy-wrapper'>
                        <div className='multimedia__copy-text' dangerouslySetInnerHTML={{ __html: multimediaCopyText }} />
                        {brandVariation !== 'b2b' && (
                            <div className='multimedia__cta-desktop'>
                                <Cta className='cta--horizon' ctaList={ctaList} ctaRef={multimediaCta.flexibleCta} />
                            </div>
                        )}
                    </div>
                    
                    <div className='multimedia__checklist' dangerouslySetInnerHTML={{ __html: multimediaCopyList }} />
                    
                    <div className='multimedia__bottom-icons'>
                        <div className='multimedia__icon-horizon' >            
                            <img loading='lazy' src={multimediaGridLeft.sourceUrl} alt={multimediaGridLeft.altText} />
                        </div>
                        {/* <div className='multimedia__marketplace' dangerouslySetInnerHTML={{ __html: multimediaAppstoreLink.url }} /> */}
                    </div>

                    {brandVariation !== 'b2b' && (
                        <div className='multimedia__cta-mobile'>
                            <Cta className='cta--horizon' ctaList={ctaList} ctaRef={multimediaCta.flexibleCta} />
                        </div>
                    )}
                        
                </div>
            </div>
        </section>
    )
}

export default multimedia