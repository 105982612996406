import React, { useEffect, useState } from 'react'
import { throttle } from '../../../assets/js/utils'
import Heading from '../Heading'
import Cta from '../Cta'

const about = ({ data, ctaList }) => {
    const isBrowser = () => typeof window !== "undefined"
    const {
        aboutTitle,
        aboutSubtitle,
        aboutText,
        aboutCta,
        aboutImageMobile,
        aboutImageDesktop
    } = data

    const [aboutImage, setAboutImage] = useState(0)

    const getAboutImage = () => {
        const image = isBrowser() && window.innerWidth > 991 ? aboutImageDesktop : aboutImageMobile;
        setAboutImage(image);
    }

    isBrowser() && throttle(
        window.addEventListener('resize', () => getAboutImage()),
        500
    );

    useEffect(() => getAboutImage());

    return (
        <section className='about-horizon section-has-cta'>
            <div className='about-horizon__image' style={{backgroundImage: `url(${aboutImage.sourceUrl})`}}>
                <div className='about-horizon__bullet bullet-parallax'/>
            </div>
            <div className='about-horizon__content'>
                <div className='about-horizon__wrapper'>
                    <div className='about-horizon__heading'>
                        <Heading title={aboutTitle} subtitle={aboutSubtitle} />
                    </div>
                    <div className='about-horizon__text' dangerouslySetInnerHTML={{ __html: aboutText }} />
                    <div className='about-horizon__cta'>
                        <Cta className='cta--horizon' ctaList={ctaList} ctaRef={aboutCta.flexibleCta} />
                    </div>
                </div>
            </div>

        </section>
    )
}

export default about