import React from 'react'
import playIcon from '../../assets/images/global/play.png'
import replayIcon from '../../assets/images/global/replay.png'
import pauseIcon from '../../assets/images/global/pause.png'

const framedSteps = ({ data }) => {
    const {
        soundCardsText,
        soundExampleCards
    } = data
 
    return (
        <section className='sound-cards'>
            <div className='sound-cards__wrapper'>
                <div className='sound-cards__text' dangerouslySetInnerHTML={{ __html: soundCardsText }} />
                {soundExampleCards?.map((card, i) => {
                    const { image, sound, text } = card
                    return (
                        <article key={i} className='sound-card'>
                            <img src={image.sourceUrl} alt={image.altText} />
                            <div className='sound-card__body'>
                                <div className='sound-card__audio-player'>
                                    <audio>
                                        <source src={sound.mediaItemUrl} type='audio/mpeg' />
                                    </audio>
                                    <button className='sound-card__play-button'>
                                        <img className='sound-card__play-icon sound-card__play-icon--play' src={playIcon} alt='play sound' />
                                        <img className='sound-card__play-icon sound-card__play-icon--replay' src={replayIcon} alt='replay sound' />
                                        <img className='sound-card__pause-icon' src={pauseIcon} alt='pause sound' />
                                    </button>
                                    <progress value='0' max='1' className='sound-card__bar' style={{opacity: 0}} />
                                </div>
                                <p className='sound-card__text' dangerouslySetInnerHTML={{ __html: text }} />
                            </div>
                        </article>
                    )
                })}
            </div>
        </section>
    )
}

export default framedSteps