import React from "react"
import { graphql } from "gatsby"

import Homepage from '../Homepage'
import Horizon from '../Horizon'
import AudibenePro from '../AudibenePro'
import SoundExamples from '../SoundExamples'
import AppPage from '../AppPage'
import Product from '../Product'
import Manufacturer from '../Manufacturer'
import FlexibleContent from '../FlexibleContent'
import Press from '../Press'
import PressArchive from '../PressArchive'
import PressArticle from '../PressArticle'
import HoerenHeute from '../HoerenHeute'

const page = ({ data, pageContext }) => {
  const { 
    templateHomepage,
    templateProduct,
    templateManufacturer,
    templatePress,
    templatePressArchive,
    templatePressArticle,
    templateHorizon,
    templateAudibenePro,
    templateSoundExamples,
    templateAppPage,
    templateFlexibleContent,
    templateHoerenHeute
  } = pageContext;

  const assignTemplate = () => {
    if (templateHomepage) return <Homepage data={data} pageContext={pageContext} />
    if (templateHorizon) return <Horizon data={data} pageContext={pageContext} />
    if (templateAudibenePro) return <AudibenePro data={data} pageContext={pageContext} />
    if (templateSoundExamples) return <SoundExamples data={data} pageContext={pageContext} />
    if (templateAppPage) return <AppPage data={data} pageContext={pageContext} />
    if (templateProduct) return <Product data={data} pageContext={pageContext} />
    if (templateManufacturer) return <Manufacturer data={data} pageContext={pageContext} />
    if (templateFlexibleContent) return <FlexibleContent data={data} pageContext={pageContext} />
    if (templatePress) return <Press data={data} pageContext={pageContext} />
    if (templatePressArchive) return <PressArchive data={data} pageContext={pageContext} />
    if (templatePressArticle) return <PressArticle data={data} pageContext={pageContext} />
    if (templateHoerenHeute) return <HoerenHeute data={data} pageContext={pageContext} />

    else return <div>Default page type</div>
  }

  return assignTemplate();
}

export default page;

export const query = graphql`
  query mypage(
      $id: String!, 
      $templateHomepage: Boolean!, 
      $templateHorizon: Boolean!, 
      $templateAudibenePro: Boolean!, 
      $templateSoundExamples: Boolean!, 
      $templateAppPage: Boolean!, 
      $templateProduct: Boolean!, 
      $templateFlexibleContent: Boolean!,
      $templateManufacturer: Boolean!,
      $templatePress: Boolean!,
      $templatePressArchive: Boolean!,
      $templatePressArticle: Boolean!
    ) {
    page: wpPage(id: { eq: $id }) {
      ...seo
      ...PageData
      ...Author
      ...wpChildren
      ...wpParent
      ...CTRLPages
      ...HomepageContent @include(if:$templateHomepage)
      ...HorizonContent @include(if:$templateHorizon)
      ...AudibeneProContent @include(if:$templateAudibenePro)
      ...SoundExamplesContent @include(if:$templateSoundExamples)
      ...AppPageContent @include(if:$templateAppPage)
      ...ProductContent @include(if:$templateProduct)
      ...ManufacturerContent @include(if:$templateManufacturer)
      ...FlexibleContentContent @include(if:$templateFlexibleContent)
      ...PressContent @include(if:$templatePress)
      ...PressArchiveContent @include(if:$templatePressArchive)
      ...PressArticleContent @include(if:$templatePressArticle)
    }
    options: wp {
      ...OPTAudibeneSettings
    }
  }
`
