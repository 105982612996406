export const animations = () => {
	const animatedItems = [
		{
			element: '.hero__triangle-left-pseudo',
			animatedValue: 'scaleY(1)',
			type: 'transform',
			delay: '0',
			animatedClass: 'hero__triangle--animated'
		},
		{
			element: '.hero__triangle-right-pseudo',
			animatedValue: 'scaleY(1)',
			type: 'transform',
			delay: '0',
			animatedClass: 'hero__triangle--animated'

		},
		{
			element: '.hero__triangle-under-pseudo',
			animatedValue: 'scaleY(1)',
			type: 'transform',
			delay: '0',
			animatedClass: 'hero__triangle--animated'

		},
		{
			element:'.hero__title',
			animatedValue: '0',
			type: 'translate',
			delay: '200',
			animatedClass: 'hero__title--animated'

		},
		{
			element: '.hero__title--second',
			animatedValue: '0',
			type: 'translate',
			delay: '200',
			animatedClass: 'hero__title--animated'

		},
		{
			element: '.hearing-aid-animated',
			type:'animate.css',
			delay: '100',
			animation: 'fadeInUp'
		},
	]

	const initAnimation = () => {
		!sessionStorage.getItem('animationsRan') ? setTimeout(animateItems, 1500) : animateItems();
	}

	const animateItems = () => {
		animatedItems.forEach((item) => {
			switch (item.type) {
				case 'transform': 
					let triangle2 =  document.querySelector(item.element);
					triangle2 && setTimeout(() => {
						triangle2.style.transform = item.animatedValue;
					}, item.delay);
					break;

				case 'translate':
					let animatedText = document.querySelector(item.element);
					animatedText && setTimeout(() => {
						animatedText.style.transform = `translateY(${item.animatedValue})`;
						animatedText.style.opacity = '1';
					}, item.delay);
					break;

				case 'animate.css':
					let animatedItem = document.querySelector(item.element);
					animatedItem && setTimeout(() => {
						animatedItem.style.opacity = '1';
						animatedItem.classList.add('animated');
						animatedItem.classList.add(item.animation);
					}, item.delay);
			}
		})
	};

	initAnimation();
	
	
	const targets = document.querySelectorAll('.img-text--image-top .img-text__triangle');

	targets && window.addEventListener('scroll', (e) => {
		let rateValue = window.matchMedia('(max-width: 600px)').matches ? .05 : .125;
		targets.forEach((target) => {
			let targetRect = target.getBoundingClientRect();
			let rate = (targetRect.y - window.innerHeight) * rateValue ;
			if(window.scrollY >= (targetRect.top - target.offsetHeight)){
				target.style.transform = `translateY(${rate}px)`;
			}
		})

	})
}