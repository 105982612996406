import React, { Component } from 'react'
import { picturefill } from './libs/picturefill'
import { navigationJs } from './navigation'
import { animations } from './animations'
import { ctaForm } from './ctaForm'
import { soundCards } from './soundCards'
import { downloadApp } from './downloadApp'
import { intro } from './intro'

import { hero } from './horizon/hero'
import { horizonSoundCards } from './horizon/soundCards'
import { xModels } from './horizon/xModels'


class scripts extends Component {
	componentDidMount() {
		picturefill()

		ctaForm()
		navigationJs()
		animations()
		soundCards()
		intro()
		downloadApp()
		
		hero()
		horizonSoundCards()
		xModels()
	}

	render() {
		return <></>
	}
}

export default scripts
