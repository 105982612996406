import React from 'react'
import Heading from './Heading'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Cta from './Cta'
import Author from './Author'

const intro = ({ data, pageContext, pageTitle, authorList, ctaList, template, type}) => {
    const {
        introImage,
        introMainText,
        introTitle,
        introSubtitle,
        sections,
        disableAuthor,
        associateAuthor,
        disableFlexibleCta,
        flexibleCta
    } = data

    const { breadcrumb } = pageContext;
    const crumbs = breadcrumb.crumbs.map(crumb => {
        return {
            pathname: crumb.pathname,
            crumbLabel: crumb.crumbLabel.replace('-', ' ')
        }
    })

    return (
        <section className='intro'>
            <div className='breadcrumbs__wrapper'>
                <Breadcrumb crumbs={crumbs} crumbLabel={pageTitle} crumbSeparator='›' />
            </div>

            <div className='intro__heading'>
                <Heading title={introTitle} subtitle={introSubtitle} />
            </div>

            <div className='intro__content'>
                {type === 'informational' && !disableAuthor && (
                    <div className='intro__author'>
                        <Author authorList={authorList} authorRef={associateAuthor} />
                    </div>
                )}
                    
                <div className='intro__main-text' dangerouslySetInnerHTML={{ __html: introMainText }} />
            </div>
            
            <div className='intro__left-side'>
                {type === 'informational' ? (
                    <ul className='intro__nav-list'>
                        {sections?.map((section, i) => (
                            <li 
                                key={i} 
                                className='intro__nav-element' 
                                data-scroll-to={`#id-${section.sectionId}`}
                                dangerouslySetInnerHTML={{ __html: section.sectionTitle }}
                            />
                        ))}
                    </ul>
                ) : (
                    <div className='intro__image'>
                        {introImage && (
                            <img src={introImage.sourceUrl} alt={introImage.altText} />
                        )}
                    </div>
                )}
            </div>
            
            {flexibleCta && !disableFlexibleCta && (
                <div className='intro__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}
            <div className='separator'></div>
            
        </section>
    )
}

export default intro