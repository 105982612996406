import React from 'react'
import Heading from '../Heading'
import SoundCard from './SoundCard'

const sound = ({ data }) => {
    const {
        brandVariation,
        simulatorHeading,
        simulatorTitle,
        simulatorOff,
        simulatorOn,
        simulatorBgMobile,
        simulatorBgDesktop,
        simulatorMainImageMobile,
        simulatorMainImageDesktop,
        fokusTitle,
        fokusCopyTitle,
        fokusImage,
        fokusText,
        fokusTooltip,
        fokusTooltipEffect,
        fokusSound1,
        fokusSound2,
        relaxTitle,
        relaxCopyTitle,
        relaxImage,
        relaxText,
        relaxTooltip,
        relaxTooltipEffect,
        relaxSound1,
        relaxSound2,
        multimediaBgDesktop,
        multimediaBgMobile
    } = data

    return (
        <section className='sound-simulator'>
            <div className='sound-simulator__image'>
                <div className='sound-simulator__background sound-simulator__background--mobile'>
                    <div className='parallax parallax--mobile' style={{backgroundImage: `url(${simulatorBgMobile.sourceUrl})`}} >
                    </div>
                    <div className='parallax parallax--desktop' style={{backgroundImage: `url(${simulatorBgDesktop.sourceUrl})`}} >
                    </div>
                    <div className='sound-simulator__background sound-simulator__background--mobile' style={{opacity: 0}}>
                        <picture>
                            <source srcSet={multimediaBgDesktop.sourceUrl}
                                alt={multimediaBgDesktop.altText} media='(min-width: 768px)' />
                            <img loading='lazy' src={multimediaBgMobile.sourceUrl}
                                alt={multimediaBgMobile.altText} />
                        </picture>
                    </div>
                </div>

                <picture className='sound-simulator__main-image'>
                    <source srcSet={simulatorMainImageDesktop.sourceUrl}
                        alt={simulatorMainImageDesktop.altText} media='(min-width: 768px)' />
                    <img loading='lazy' src={simulatorMainImageMobile.sourceUrl}
                        alt={simulatorMainImageMobile.altText} />
                </picture>
            </div>
            <div className='sound-simulator__copy'>
                <h1 className='sound-simulator__heading' dangerouslySetInnerHTML={{ __html: simulatorHeading }} />
                <div className='sound-simulator__title'>
                    <Heading title={simulatorTitle} />
                </div>
            </div>
            <div className='sound-simulator__app'>
                <div className='app__cards'>
                    <SoundCard
                        title={fokusTitle}
                        image={fokusImage}
                        copyTitle={fokusCopyTitle}
                        copyText={fokusText}
                        soundDefault={fokusSound1}
                        soundProcessed={fokusSound2}
                        effect='sprach-fokus'
                        brandVariation={brandVariation}
                        tooltip={fokusTooltip}
                        tooltipEffect={fokusTooltipEffect}
                        simulatorOff={simulatorOff}
                        simulatorOn={simulatorOn}
                    />

                    <SoundCard
                        title={relaxTitle}
                        image={relaxImage}
                        copyTitle={relaxCopyTitle}
                        copyText={relaxText}
                        soundDefault={relaxSound1}
                        soundProcessed={relaxSound2}
                        effect='relax-modus'
                        brandVariation={brandVariation}
                        tooltip={relaxTooltip}
                        tooltipEffect={relaxTooltipEffect}
                        simulatorOff={simulatorOff}
                        simulatorOn={simulatorOn}
                    />
                </div>
            </div>
        </section>
    )
}

export default sound