import React from 'react'
import Cta from '../Cta'

const Form = ({ formList, formOpt, data, ctaList }) => {
    const { pageQuestionnaire, questionnaireTitle }  = formOpt
    const { formText, brandVariation, bottomCta }  = data
    const { forms }  = formList
    const form = forms && forms.filter(form => form.formUniqueId === pageQuestionnaire)[0];
    const { formUuid, formTitle } = form;

    return (
        <div className='form form--horizon'>
            <div className='form__wrapper form__wrapper--cta form__wrapper--fixed'>
                <article className='form__text' dangerouslySetInnerHTML={{ __html: formText }} />
                {brandVariation !== 'b2b' ? (
                    <div className='form__container' data-id={formUuid} data-title={questionnaireTitle ? questionnaireTitle : ''}>
                        <div className='cta-simulator cta-simulator--has-form'>
                            <div className='cta__slant'/>
                            <div className='cta__text'>
                                <span dangerouslySetInnerHTML={{ __html: formTitle }} />
                                <span className='cta__arrow'/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='form__cta'>
                        <Cta className='cta--horizon' ctaList={ctaList} ctaRef={bottomCta.flexibleCta} />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Form