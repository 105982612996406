import React from 'react'
import Heading from './Heading'

const trustpilot = ({ data, country }) => {
    const {
        title,
        businessId,
        tpLink
    } = data

    const ref = React.useRef(null);
    React.useEffect(() => window.Trustpilot && window.Trustpilot.loadFromElement(ref.current, true), []);

    return (
        <section className='trustpilot'>
            <div className='trustpilot__heading'>
                <Heading title={title} />
            </div>
            
            <div className='trustpilot__stars'>
                <div
                    ref={ref}
                    className='trustpilot-widget' 
                    data-locale={country} 
                    data-template-id='53aa8912dec7e10d38f59f36' 
                    data-businessunit-id={businessId}
                    data-style-height='150px' 
                    data-style-width='100%' 
                    data-theme='light' 
                    data-stars='1,2,3,4,5' 
                    >
                    {tpLink && (
                        <a href={tpLink} target='_blank' rel='noopener'>Trustpilot</a>
                    )}
                </div>
            </div>
            
            <div className='trustpilot__separator trustpilot__separator--mobile'/>
            <div className='trustpilot__separator trustpilot__separator--desktop'/>

            <div className='trustpilot__carousel'>
                <div 
                    ref={ref}
                    className='trustpilot-widget' 
                    data-locale={country} 
                    data-template-id='54ad5defc6454f065c28af8b' 
                    data-businessunit-id={businessId} 
                    data-style-height='220px' 
                    data-style-width='100%' 
                    data-theme='light' 
                    data-stars='5' 
                    data-review-languages={country.substring(0,2)}
                />
            </div>

            <div className='trustpilot__separator trustpilot__separator--mobile'/>

        </section>
    )
}

export default trustpilot