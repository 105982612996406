import React from 'react'
import Nav from './Nav/Nav'

const Header = ({data}) => {
    const { logo, logoWhite } = data;

	return (
		<header id='masthead' className='header header--horizon'>
            <div className='header__wrapper'>
                <div className='header__left-side'>
                    <div className='header__logo'>
                        <img alt='logo' src={logo} />
                    </div>
                    <div className='header__logo header__logo--white'>
                        <img alt='logo' src={logoWhite} />
                    </div>
                </div>

                <div className='header__right-side'>
                    <Nav menuLocation='HORIZON' />
                </div>

            </div>
            
            <div className='header__shape-wrapper'>
                <div className='header__shape'></div>
            </div>
        </header>
	)

}

export default Header