import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const usp = ({ data, ctaList }) => {
    const {
        uspList,
        uspsTitle,
        uspsSubtitle,
        hearingAidDevice,
        flexibleCta
    } = data

    return (
        <section className='usp'>

            <div className='usp__wrapper'>
                <div className='usp__hearing-aid hearing-aid-animated'>
                    <img src={hearingAidDevice.sourceUrl} alt={hearingAidDevice.altText} />
                </div>

                <div className='usp__content'>
                    <div className='usp__heading'>
                        <Heading title={uspsTitle} subtitle={uspsSubtitle} />
                    </div>
                    
                    <ul className='usp__list'>
                        {uspList?.map((usp, i) => (
                            <li key={i} className='usp__list' dangerouslySetInnerHTML={{ __html: usp.usp }}/>
                        ))}
                    </ul>
                </div>

            </div>

            <div className='usp__cta'>
                <Cta ctaList={ctaList} ctaRef={flexibleCta} />
            </div>

        </section>
    )
}

export default usp