import React from 'react'
import { graphql, StaticQuery, Link } from 'gatsby'
import Submenu from './Submenu'

const Nav = ({menuLocation}) => {
	return (
		<StaticQuery
			query={graphql`
			{
                allWpMenu {
                    nodes {
                        locations
                        menuItems {
                            nodes {
                                label
                                description
                                url
                                cssClasses
                                databaseId
                                parentDatabaseId
                                connectedNode {
                                    node {
                                        databaseId
                                    }
                                }
                                childItems {
                                    nodes {
                                        label
                                        url
                                        cssClasses
                                        databaseId
                                        parentDatabaseId
                                        connectedNode {
                                            node {
                                            databaseId
                                            }
                                        }
                                        childItems {
                                        nodes {
                                            label
                                            url
                                            cssClasses
                                            databaseId
                                            parentDatabaseId
                                            connectedNode {
                                                node {
                                                databaseId
                                                }
                                            }
                                        }
                                    }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `}
	
		render={({allWpMenu}) => {
            {/* TODO: add class current-menu-sm-item to current page */}
            const currentPageId = '123';
            const selectedNav = allWpMenu.nodes.filter(el => el.locations.includes(menuLocation));
			const { menuItems: { nodes } } = selectedNav[0];

			return (
                <nav id='site-navigation' className='main-navigation' >
                    <button className='menu-toggle' title='menu toggle' aria-controls='primary-menu' aria-expanded='false'>
                        <div className='hamburger'>
                            <span className='hamburger__line'></span>
                            <span className='hamburger__line'></span>
                            <span className='hamburger__line'></span>
                        </div>
                    </button>

                    <div className='site-navigation-md'>
                        <div className='site-navigation-container'>
                            <ul className='menu nav-menu'>
                            {nodes && nodes.map((item, i) => {
                                if(!item.parentDatabaseId) {
                                    const currentPage = item.connectedNode && item.connectedNode.node.databaseId === currentPageId ? 'current-menu-item' : '';
                                    const hasChildren = item.childItems.nodes.length > 0;
                                    const classHasMegaMenu = hasChildren ? 'menu-item-has-children' : '';
                                    
                                    return (
                                    <React.Fragment key={i}>
                                        <li 
                                            className={`menu-item menu-item--level-0 menu-item-${item.databaseId} ${currentPage} ${classHasMegaMenu}`}
                                            data-menu-id={hasChildren ? item.databaseId : false}
                                            data-level={hasChildren ? '0' : false}
                                            >
                                            {hasChildren ? item.label : (
                                                <>
                                                    <Link to={item.url} title={item.label}>
                                                        {item.label}
                                                    </Link>
                                                    <div className='menu-item__underline'/>
                                                </>
                                            )}
                                        </li>
                                        {hasChildren && (
                                            <div className={`mega-menu menu--hide mega-menu-${item.databaseId}`}>
                                                <div className='mega-menu-container'>
                                                    <Submenu menu={item.childItems.nodes} parentDatabaseId={item.databaseId} level={0} />
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                    )
                                }
                            })}
                            </ul>
                        </div>

                    </div>
                </nav>
			)
		}}
		/>
	)

}

export default Nav