import React from 'react'
import Flickity from 'react-flickity-component'
import Heading from './Heading'

const carousel = ({ data, carouselList, template }) => {
    const {
        item,
        title
    } = data

    const flickityOptions = {
        initialIndex: 1,
        pageDots: true,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: 3,
        arrowShape: {
            x0: 50,
            x1: 60,
            y1: 50,
            x2: 65,
            y2: 50,
            x3: 55,
        }
    }

    return (
        <section className='carousel-pro' >
            <div className='carousel-pro__heading'>
                <Heading title={title} />
            </div>

            <div className='carousel-pro__wrapper'>
            
            <Flickity
                className={'flickity-carousel'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >
                {item?.map((el, i) => {
                    return (
                        <div className='carousel-pro__item' key={i}>
                            <div className='carousel-pro__image'>
                                <img loading='lazy' className='carousel-pro__image' src={el.image.sourceUrl}  alt={el.image.altText} />
                            </div>
                            <div className='carousel-pro__body'>
                                <div className='carousel-pro__main-text' dangerouslySetInnerHTML={{ __html: el.mainText }} />
                                <div className='carousel-pro__secondary-text' dangerouslySetInnerHTML={{ __html: el.secondaryText }} />
                                <div className='carousel-pro__cta'>
                                    <a href={el.cta.url} title={el.cta.title} target={el.cta.target} >
                                        {el.cta.title} <div className='card__arrow card__arrow--light'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </Flickity>
                
            </div>
        </section>
    )
}

export default carousel