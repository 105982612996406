import React from 'react'
import Heading from './Heading'
import appIcon from '../../assets/images/app/app-icon.svg'

const downloadApp = ({ data }) => {
    const {
        brandColour,
        downloadAppTitleMobile,
        downloadAppTitleDesktop,
        appstore,
        downloadAppCta,
        successMessageMobile,
        successMessageDesktop,
        vimeoIdAndroid,
        vimeoIdIos,
        whatSmartphone,
        stepsText
    } = data

    const { urlAndroid, urlIos, logoAndroid, logoIos } = appstore
    const { step1Android, step1Ios, step2Android, step2Ios, step3, step4 } = stepsText
 
    return (
        <section className='download-app' style={{backgroundColor: brandColour}} >
            <div className='download-app__container'>
                <div className='download-app__wrapper download-app__wrapper--mobile'>
                    <div className='download-app__heading'>
                        <Heading title={downloadAppTitleMobile} />
                    </div>
                    <a title='HORIZON Google Play' href={urlAndroid} target='_blank' className='download-app__cta hide-ios'>
                        <div className='download-app__cta__wrapper'>
                            <img src={appIcon} alt='audibene HORIZON App logo'/>
                            <span dangerouslySetInnerHTML={{ __html: downloadAppCta }} />
                        </div>
                    </a>
                    <a title='HORIZON App Store' href={urlIos} target='_blank' className='download-app__cta hide-android'>
                        <img src={appIcon} alt='audibene HORIZON App logo'/>
                        <span dangerouslySetInnerHTML={{ __html: downloadAppCta }} />
                    </a>
                    <div className='download-app__success'>
                        <h3 className='download-app__success__text' dangerouslySetInnerHTML={{ __html: successMessageMobile }} />
                    </div>
                    <div className='download-app__video'>
                        <div className='download-app__video__container js-vimeo-mobile' data-vimeo-id-android={vimeoIdAndroid} data-vimeo-id-ios={vimeoIdIos}>
                            <script src='https://player.vimeo.com/api/player.js'></script>
                        </div>
                    </div>
                </div>
                
                <div className='download-app__wrapper download-app__wrapper--desktop'>
                    <div className='download-app__view download-app__view--select-os active'>
                        <div className='download-app__heading'>
                            <Heading title={downloadAppTitleDesktop} subtitle={whatSmartphone} />
                        </div>
                        <div className='download-app__devices'>
                            <div className='download-app__device' data-os='android'>
                                <p className='download-app__device__name'>Android</p>
                                <div className='download-app__icon'>
                                    <svg className='androidIcon' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' viewBox='-147 -70 294 345'><g fill={brandColour}><use strokeWidth='14.4' xlinkHref='#b' stroke='transparent'></use><use xlinkHref='#a' transform='scale(-1,1)'></use><g id='a' stroke='transparent' strokeWidth='7.2'><rect rx='6.5' transform='rotate(29)' height='86' width='13' y='-86' x='14'></rect><rect id='c' rx='24' height='133' width='48' y='41' x='-143'></rect><use y='97' x='85' xlinkHref='#c'></use></g><g id='b'><ellipse cy='41' rx='91' ry='84'></ellipse><rect rx='22' height='182' width='182' y='20' x='-91'></rect></g></g><g stroke='#FFF' strokeWidth='7.2' fill='#FFF'><path d='m-95 44.5h190'></path><circle cx='-42' r='4'></circle><circle cx='42' r='4'></circle></g></svg>
                                </div>
                            </div>
                            <div className='download-app__device' data-os='ios'>
                                <p className='download-app__device__name'>iOS</p>
                                <div className='download-app__icon'>
                                    <svg id='apple-icon' viewBox='2 2 22 21'>
                                    <path fill={brandColour} d='M18.71,19.5C17.88,20.74 17,21.95 15.66,21.97C14.32,22 13.89,21.18 12.37,21.18C10.84,21.18 10.37,21.95 9.1,22C7.79,22.05 6.8,20.68 5.96,19.47C4.25,17 2.94,12.45 4.7,9.39C5.57,7.87 7.13,6.91 8.82,6.88C10.1,6.86 11.32,7.75 12.11,7.75C12.89,7.75 14.37,6.68 15.92,6.84C16.57,6.87 18.39,7.1 19.56,8.82C19.47,8.88 17.39,10.1 17.41,12.63C17.44,15.65 20.06,16.66 20.09,16.67C20.06,16.74 19.67,18.11 18.71,19.5M13,3.5C13.73,2.67 14.94,2.04 15.94,2C16.07,3.17 15.6,4.35 14.9,5.19C14.21,6.04 13.07,6.7 11.95,6.61C11.8,5.46 12.36,4.26 13,3.5Z'></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='download-app__view download-app__view--instructions'>
                        <div className='download-app__steps'>
                            <div className='download-app__step download-app__step--1 hide-ios'>
                                <div className='download-app__step__number'>
                                    1
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step1Android }} />
                                </div>
                            </div>
                            <div className='download-app__step download-app__step--1 hide-android'>
                                <div className='download-app__step__number'>
                                    1
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step1Ios }} />
                                </div>
                            </div>
                            <div className='download-app__step download-app__step--2 hide-ios'>
                                <div className='download-app__step__number'>
                                    2
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step2Android }} />
                                    <a className='download-app__store-logo' title='HORIZON Google Play' href={urlAndroid} target='_blank'>
                                        <img alt='HORIZON Google Play' src={logoAndroid.sourceUrl} />
                                    </a>
                                </div>
                            </div>
                            <div className='download-app__step download-app__step--2 hide-android'>
                                <div className='download-app__step__number'>
                                    2
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step2Ios }} />
                                    <a className='download-app__store-logo' title='HORIZON App Store' href={urlIos} target='_blank'>
                                        <img alt='HORIZON App Store' src={logoIos.sourceUrl} />
                                    </a>
                                </div>
                            </div>
                            <div className='download-app__step download-app__step--3'>
                                <div className='download-app__step__number'>
                                    3
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step3 }} />
                                    <div className='download-app__app-logo'>
                                        <img src={appIcon} alt='audibene HORIZON App logo'/>
                                    </div>
                                </div>
                            </div>
                            <div className='download-app__step download-app__step--4'>
                                <div className='download-app__step__number'>
                                    4
                                </div>
                                <div className='download-app__step__body'>
                                    <p className='download-app__step__text' dangerouslySetInnerHTML={{ __html: step4 }} />
                                </div>
                            </div>
                        </div>
                        <div className='download-app__success'>
                            <h3 className='download-app__success__text' dangerouslySetInnerHTML={{ __html: successMessageDesktop }} />
                        </div>
                        <div className='download-app__video'>
                            <div className='download-app__video__container js-vimeo-desktop' data-vimeo-id-android={vimeoIdAndroid} data-vimeo-id-ios={vimeoIdIos}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default downloadApp