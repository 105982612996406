import React from 'react'
import Nav from './Nav/Nav'

const Header = ({data}) => {
    const { headerLogo, headerOpeningHours, headerPhoneNumber } = data;

	return (
		<header id='masthead' className='header'>
            <div className='header__wrapper'>
                <div className='header__logo'>
                    {/* TODO: get home url */}
                    <a href='/' rel='home'>
                        <img alt='logo' src={headerLogo} />
                    </a>
                </div>

                <div className='header__right-side'>

                <Nav menuLocation='HEADER' />

                    <div className='header__contact'>
                        <div className='header__phone-hours'>
                            <a className='header__phone' href={headerPhoneNumber.url} target={headerPhoneNumber.target}>
                                {headerPhoneNumber.title}
                            </a>
                            <span 
                                className='header__hours'
                                dangerouslySetInnerHTML={{ __html: headerOpeningHours }} />
                        </div>
                        <a  href={headerPhoneNumber.url} target={headerPhoneNumber.target}>
                            <div className='header__phone-icon'/>
                        </a>
                    </div>
                </div>

            </div>
            
            <div className='header__shape-wrapper'>
                <div className='header__shape'></div>
            </div>
        </header>
	)

}

export default Header