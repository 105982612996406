import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const imageText = ({ data, ctaList }) => {
    const {
        addTriangles,
        sectionId = '',
        template = '',
        bottom,
        bottomText,
        flexibleCta,
        layout,
        subtitle,
        text,
        title,
        titlePosition
    } = data

    const imageDesktop = data.imageDesktop || data.imageMobile
    const imageMobile = data.imageMobile || data.imageDesktop
 
    return (
        <section id={`id-${sectionId}`} className={`img-text img-text--${layout} ${text ? 'img-text--img-only' : '' } ${template === 'Product Page' ? 'img-text--product-page' : ''} ${titlePosition === 'side' ? 'img-text--side' : ''}`}>

            {(imageDesktop || imageMobile) && (
                <div className='img-text__image'>
                {addTriangles && (
                    <>
                        <div className='img-text__triangle img-text__triangle--left'>
                            <svg width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                                <polygon points='100, 0 0,100 0,0'></polygon>
                            </svg>
                        </div>
                        <div className='img-text__triangle img-text__triangle--right'>
                            <svg width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                                <polygon points='0,0 100,0 100,100'></polygon>
                            </svg>
                        </div>
                    </>
                )}

                    <div className='img-text__image-cont img-text__image-cont--parallax'>
                        <picture>
                            <source srcSet={imageDesktop.sourceUrl}
                                alt={imageDesktop.altText} media='(min-width: 768px)'/>
                                
                            <img src={imageMobile.sourceUrl}
                                alt={imageMobile.altText} />
                        </picture>
                    </div>
                </div>
            )}
            
            {titlePosition !== 'side' && (title || subtitle) && (
                <div className={`img-text__heading img-text__heading--${titlePosition}`}>
                        <Heading title={title} subtitle={subtitle} />
                </div>
            )}

            <div className='img-text__text'>
                {titlePosition === 'side' && (title || subtitle) && (
                    <div className={`img-text__heading img-text__heading--${titlePosition}`}>
                        <Heading title={title} subtitle={subtitle} />
                    </div>
                )}
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
            
            {bottomText && (
                <div 
                    className='img-text__text img-text__text--bottom'
                    dangerouslySetInnerHTML={{ __html: bottomText }}
                    />
            )}
            
            {(bottom === 'cta' || bottom === 'cta-separator') && (
                <div className='img-text__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}

            {(bottom === 'separator' || bottom === 'cta-separator') && (
                <div className='separator'/>
            )}
        </section>
    )
}

export default imageText