import React from 'react'

const showcase = ({ data }) => {
    const {
        sectionId = '',
        image,
        mainText,
        ctaDesktop,
        ctaMobile,
        name,
        position
    } = data

    return (
        <section id={`id-${sectionId}`} className='showcase'>
            <div className='showcase__wrapper'>
                {image && (
                    <div className='showcase__image showcase__image--mobile'>
                        <picture>
                            <img src={image.sourceUrl} alt={image.altText} />
                        </picture>
                    </div>
                )}

                <div className='showcase__body'>
                    <div className='showcase__main-text' dangerouslySetInnerHTML={{ __html: mainText }} />

                    <div className='showcase__cta showcase__cta--desktop'>
                        <a href={ctaDesktop.url} title={ctaDesktop.title} target={ctaDesktop.target}>
                            <span>{ctaDesktop.title}</span>
                            <div className='card__arrow card__arrow--light'></div>
                        </a>
                    </div>
                </div>

                <div className='showcase__image showcase__image--desktop'>
                    <img src={image.sourceUrl} alt={image.altText} />
                    <div className='showcase__image-text'>
                        <p className='showcase__name' dangerouslySetInnerHTML={{ __html: name }} />
                        <p className='showcase__position' dangerouslySetInnerHTML={{ __html: position }} />
                    </div>
                </div>

                <div className='showcase__cta showcase__cta--mobile'>
                    <a href={ctaMobile.url} title={ctaMobile.title} target={ctaMobile.target}>
                        <span>{ctaMobile.title}</span>
                        <div className='card__arrow card__arrow--light'/>
                    </a>
                </div>

            </div>
        </section>
    )
}

export default showcase