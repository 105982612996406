import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const productFeatures = ({ data, ctaList }) => {
    const {
        bottom,
        flexibleCta,
        subtitle,
        text,
        title
    } = data

    return (
        <>
            <section className='product-features'>
                <div className='product-features__heading'>
                    <Heading title={title} subtitle={subtitle} />
                </div>

                <div className='product-features__text' dangerouslySetInnerHTML={{ __html: text }} />
                
                {bottom === 'cta' || bottom === 'cta-separator' && (
                    <div className='product-features__cta'>
                        <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                    </div>
                )}
            </section>

            {(bottom === 'separator' || bottom === 'cta-separator') && (
                <div className='separator'></div>
            )}
        </>
    )
}

export default productFeatures