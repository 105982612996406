import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero.homepage'
import Usp from '../components/FlexibleContent/Usp'
import ImageText from '../components/FlexibleContent/ImageText'
import Steps from '../components/FlexibleContent/Steps'
import Carousel from '../components/FlexibleContent/Carousel'
import Cards from '../components/FlexibleContent/Cards'
import Trustpilot from '../components/FlexibleContent/Trustpilot'
import About from '../components/FlexibleContent/About'
import Form from '../components/FlexibleContent/Form'

const Homepage = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    CTRLPages, 
    TPLHomepage, 
    isFrontPage,
    author,
    seo,
    template
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    forms: { OPTForms },
    headerSettings: { OPTHeaderSettings },
    productCarousels: { OPTProductCarousels }
  } = options

  const { homepageSections } = TPLHomepage

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'ImageText':
        return <ImageText data={data} key={index} ctaList={OPTCTAButtons} />

      case 'Steps':
        return <Steps data={data} key={index} ctaList={OPTCTAButtons} />

      case 'Carousel':
        return <Carousel data={data} key={index} carouselList={OPTProductCarousels} />

      case 'Trustpilot':
        return <Trustpilot data={data} country={OPTAudibeneSettings.country} key={index} />

      default:
        return <p key={index}>{contentType}</p>
    }
  }

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-homepage'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLHomepage} isFrontPage={isFrontPage} />
        <Usp data={TPLHomepage} ctaList={OPTCTAButtons} />

        {homepageSections && homepageSections.map((section, index) => {
          const contentType = section.fieldGroupName.replace('page_Tplhomepage_HomepageSections_', '');
          return assignComponent(contentType, section, index);
        })}

        <About data={TPLHomepage} />
        <Cards data={TPLHomepage} />
        <Form data={CTRLPages} formList={OPTForms} />
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default Homepage