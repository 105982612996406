import React from 'react'
import Flickity from 'react-flickity-component'
import Cta from '../Cta'

const carousel = ({ data, carouselList, ctaList }) => {
    const {
        productCarousel: { associateCarousel, flexibleCta },
        brandVariation
    } = data
    const { carousels } = carouselList
    const carousel =  carousels && carousels.filter(el => el.carouselUniqueId === associateCarousel)[0]

    const { products } = carousel

    const flickityOptions = {
        initialIndex: 0,
        pageDots: true,
        wrapAround: true,
        imagesLoaded: true,
        reloadOnUpdate: true
    }

    return (
        <section className='carousel section-has-cta carousel--horizon' >

            <div className='carousel__container'>
                <Flickity
                    className={'flickity-carousel carousel__wrapper'} // default ''
                    elementType={'div'} // default 'div'
                    options={flickityOptions} // takes flickity options {}
                    disableImagesLoaded={false} // default false
                    reloadOnUpdate // default false
                    static // default 
                >
                    {products?.map((item, i) => {
                        const action = item.productLink === 'url' ? item.productUrl : (item.productPage && item.productPage.link);
                        const carouselItem = (
                            <div className='carousel__item' key={i}>
                                {item.productLogo && (
                                    <div className='carousel__head'>
                                        <img loading='lazy' className='carousel__logo' src={item.productLogo.sourceUrl} />
                                        {/* <img loading='lazy' className='carousel__logo' data-flickity-lazyload={item.productLogo.sourceUrl} /> */}
                                    </div>
                                )}
                                <div className='carousel__body'>
                                    <img loading='lazy' className='carousel__image' src={item.productImage.sourceUrl}  alt={item.productImage.altText} />
                                </div>
                                <div className='carousel__bottom'>
                                    <span className='carousel__model-name' dangerouslySetInnerHTML={{ __html: item.modelName }} />
                                </div>
                            </div>
                        )
                        return (
                            action ? (
                                    <a href={action}>{carouselItem}</a>
                                ) : carouselItem
                        )
                    })}
                </Flickity>

                {brandVariation !== 'b2b' && (
                    <div className='carousel__cta'>
                        <Cta className='cta--horizon' ctaList={ctaList} ctaRef={flexibleCta} />
                    </div>
                )}

            </div>
        </section>
    )
}

export default carousel