import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/HeaderHorizon'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Horizon/Hero'
import Carousel from '../components/FlexibleContent/Horizon/Carousel'
import About from '../components/FlexibleContent/Horizon/About'
import Experience from '../components/FlexibleContent/Horizon/Experience'
import Sound from '../components/FlexibleContent/Horizon/Sound'
import Multimedia from '../components/FlexibleContent/Horizon/Multimedia'
import Battery from '../components/FlexibleContent/Horizon/Battery'
import XModels from '../components/FlexibleContent/Horizon/XModels'
import Form from '../components/FlexibleContent/Horizon/Form'

const Horizon = ({ data, pageContext }) => {

  const { page, options } = data
  const {
    CTRLPages, 
    TPLHorizon,
    seo,
    template
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    forms: { OPTForms },
    headerSettings: { OPTHeaderSettings },
    productCarousels: { OPTProductCarousels }
  } = options


  return (
    <>
    <Head seo={seo} data={OPTAudibeneSettings} />
    <div className='page-template-horizon'>
      <div className={TPLHorizon.brandVariation}>
        <Header data={TPLHorizon} />
        <div className='horizon-landing'>
          <Hero data={TPLHorizon} />
          <Carousel data={TPLHorizon} carouselList={OPTProductCarousels} ctaList={OPTCTAButtons} />
        </div>
        <About data={TPLHorizon} ctaList={OPTCTAButtons} />
        <Experience data={TPLHorizon} ctaList={OPTCTAButtons} />
        <Sound data={TPLHorizon} />
        <Multimedia data={TPLHorizon} ctaList={OPTCTAButtons} />
        <Battery data={TPLHorizon} />
        <XModels data={TPLHorizon} ctaList={OPTCTAButtons} />
        <Form formOpt={CTRLPages} formList={OPTForms} data={TPLHorizon} ctaList={OPTCTAButtons} />
        <Footer data={OPTFooterSettings} template={template} pageLogo={TPLHorizon?.logoWhite} />
      </div>
    </div>
    <Scripts />
    </>
  )
}

export default Horizon