import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import SplitText from '../components/FlexibleContent/SplitText.press'

const PressArticle = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    TPLPressArticle,
    wpParent,
    seo,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    footerSettings: { OPTFooterSettings },
    headerSettings: { OPTHeaderSettings }
  } = options

  const { pressSections } = TPLPressArticle

  const { breadcrumb } = pageContext;
  const crumbs = breadcrumb.crumbs.map(crumb => {
      return {
          pathname: crumb.pathname,
          crumbLabel: crumb.crumbLabel.replace('-', ' ')
      }
  })

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'SplitText':
        return <SplitText data={data} key={index} />

      default:
        return <p key={index}>{contentType}</p>
    }
  }

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-press-archive'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLPressArticle} wpParent={wpParent} />
        <div className='breadbrumbs-standalone'>
			    <div className='breadcrumb__wrapper'>
            <Breadcrumb crumbs={crumbs} crumbLabel={title} crumbSeparator='›' />
          </div>
        </div>
        {pressSections && pressSections.map((section, index) => {
          const contentType = section.fieldGroupName.replace('page_Tplpressarticle_PressSections_', '');
          return assignComponent(contentType, section, index);
        })}
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default PressArticle