export const horizonSoundCards = () => {
    if(document.querySelector('.page-template-horizon')) {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        const playerHandler = (playButton) => {
            playButton.classList.contains('card__play-button--active') &&  playButton.classList.remove('card__play-button--active'); 
        }
        
        //Play Button Handler

        const stopAllSounds = () => {
            
            document.querySelectorAll('audio').forEach(audio => {
                audio.pause();
            })
            document.querySelectorAll('.card__play-button').forEach(play => {
                const parentContainer = play.closest('.sound-simulator__card');

                play.classList.contains('card__play-button--active') && play.classList.remove('card__play-button--active')
                parentContainer.classList.contains('play') && parentContainer.classList.remove('play')
            })

            //sound-simulator__card
        }
        
        document.querySelectorAll('.io-button__toggle').forEach(button => {
            let ready = false;
            button.addEventListener('click', (e) => {
                const parentCard = document.querySelector(`.sound-simulator__card[data-effect=${button.dataset.effect}]`);
                ready = parentCard.dataset.ready === 'true';
    
                if (ready) {
                    parentCard.querySelector('.card__effect-box .card__tooltip').style.display = 'none';
    
                    let loaded = false;
                    const soundUntouched = parentCard.querySelector('.soundUntouched');
                    const soundProcessed = parentCard.querySelector('.soundProcessed');  
                    const input = parentCard.querySelector('input');
                    const box = input.parentElement.parentElement;
                    
                    input.disabled = false;
    
                    if(input.checked) {
                        if (isIOS) {
                            soundUntouched.muted = false;
                            soundProcessed.muted = true;
        
                            if(!loaded) {
                                soundUntouched.paused && soundUntouched.play();
                                loaded = true;
                            }
                        } else {
                            soundUntouched.volume = 1;
                            soundProcessed.volume = 0;
                        }
        
                        box.classList.remove(`${box.classList[0]}--active`);
                    } else {
                        if (isIOS) {
                            soundUntouched.muted = true;
                            soundProcessed.muted = false;
                            
                            if(!loaded) {
                                soundProcessed.paused && soundProcessed.play();
                                loaded = true;
                            }
                        } else {
                            soundUntouched.volume = 0;
                            soundProcessed.volume = 1;
                            
                        }
                        
                        box.classList.add(`${box.classList[0]}--active`);
                    }
    
                    parentCard.classList.toggle('effect');
                }
            })
        })
    
        document.querySelectorAll('.card__play-button').forEach((play, index) => {
            const parentContainer = play.closest('.sound-simulator__card');
            const soundUntouched = play.parentElement.querySelector('.soundUntouched');
            const soundProcessed = play.parentElement.querySelector('.soundProcessed');
            const bar = play.parentElement.querySelector('.card__bar');
    
            if (isIOS) {
                soundUntouched.muted = false;
                soundProcessed.muted = true;
            } else {
                soundUntouched.volume = 1;
                soundProcessed.volume = 0;
            }
            
            
            soundUntouched.addEventListener('timeupdate', () => {
                let progress = soundUntouched.currentTime / soundUntouched.duration;
                bar.value = progress;
                progress == 1 && play.classList.remove('card__play-button--active');
            })
            
            play.addEventListener('click', () => {
                parentContainer.dataset.ready='true';
                parentContainer.querySelector('.card__audio-player .card__tooltip').style.display = 'none';
                if (play.classList.contains('card__play-button--active')) {
                    soundUntouched.pause();
                    soundProcessed.pause();
                } else {
                    play.parentElement.querySelector('progress').style.opacity = '';
                    stopAllSounds();
                    soundProcessed.play();
                    soundUntouched.play();
                }
                play.classList.toggle('card__play-button--active');
                
                parentContainer.classList.toggle('play');
            })
        })
    
        //Sound card accordion
        document.querySelectorAll('.card__copy-title').forEach(accordion => {
            accordion.addEventListener('click', (e) => {
                if(window.matchMedia('(max-width: 767px)').matches) {
                    const parent = e.target.parentElement;
                    parent.classList.toggle('card__copy--active');
                }
            })
        });
    };
    
    var bgParallax = document.querySelectorAll('.page-template-horizon .parallax');
    
    var bgParallax = document.querySelectorAll('.page-template-horizon .parallax');

    /* Background image paralax */

    document.addEventListener('scroll', function(){
        let scrollPosition = window.pageYOffset;
        bgParallax && bgParallax.forEach(parallax => {
            const parallaxTop = parallax.getBoundingClientRect().top
            const positionPercent = parallaxTop * 100 / window.innerHeight
            const parallaxHeightPercent = parallax.offsetHeight * 100 / window.innerHeight;
            const rate = .7;
            
            if (scrollPosition > parallax.offsetTop && scrollPosition >= parallaxTop){
                parallax.style.backgroundPositionY = (100 - (parallaxHeightPercent * rate)) - (positionPercent) * rate + '%';    
            } else {
                parallax.style.backgroundPositionY = '50%';    
            }
        })
    });   
}