import { isAndroid, isIOS } from 'react-device-detect'

export const downloadApp = () => {
	const loadVideo = (device, container) => {
		const vimeoContainer = document.querySelector(`.js-vimeo-${container}`);
	
		const videoId = device == 'android' ? vimeoContainer.dataset.vimeoIdAndroid : vimeoContainer.dataset.vimeoIdIos
	
		const vimeoOptions = {
			id: videoId,
			controls: true,
			autoplay: false,
			autopause: false,
			muted: false,
			loop: false,
			height: 200
		};
	
		const vimeoElement = Vimeo && vimeoOptions.id ? new Vimeo.Player(vimeoContainer, vimeoOptions) : '';
	}

	(isAndroid || isIOS) && document.addEventListener('DOMContentLoaded', () => {
		const device = isAndroid ? 'android' : 'ios'
		loadVideo(device, 'mobile')
	})

	if (isIOS) {
		document.body.classList.add('device-ios')
	} else if (isAndroid) {
		document.body.classList.add('device-android')
	} else {
		const ctas = document.querySelectorAll('.download-app__device')
		const viewOS = document.querySelector('.download-app__view--select-os')
		const viewInstructions = document.querySelector('.download-app__view--instructions')
	
		ctas?.forEach(cta => {
			cta.addEventListener('click', () => {
				const osClass = `device-${cta.dataset.os}`
				viewOS.classList.remove('active')
				viewInstructions.classList.add('active')
				viewInstructions.classList.add(osClass)

				loadVideo(cta.dataset.os, 'desktop')
			})
		})
	}
}