import React from 'react'
import Heading from '../Heading'
import Cta from '../Cta'

const xModels = ({ data, ctaList }) => {
    const {
        xModelsImageMobile,
        xModelsImageDesktop,
        xModelsTitle,
        xModelsColorText,
        xModel
    } = data

    return (
        <section className='x-models section-has-cta'>
            <div className='x-models__head'>
                <div className='x-models__image'>
                    <picture>
                        <source srcSet={xModelsImageDesktop.sourceUrl}
                            alt={xModelsImageDesktop.altText} media='(min-width: 768px)' />
                            <img loading='lazy' src={xModelsImageMobile.sourceUrl}
                            alt={xModelsImageMobile.altText} />
                    </picture>
                </div>
            
                <div className='x-models__heading'>
                    <span dangerouslySetInnerHTML={{ __html: xModelsTitle }} />
                    <div className='x-models__bullet bullet-parallax' />
                </div>
            </div>

            <div className='x-models__buttons'>
                {xModel?.map((el, i) => (
                    <div key={i} className={`x-models__button x-models__button--${i} ${i === 0 ? 'active' : ''}`} data-slide={i} >
                        <span className='x-models__model-name-desktop' dangerouslySetInnerHTML={{ __html: el.modelNameDesktop }} />
                        <span className='x-models__model-name-mobile' dangerouslySetInnerHTML={{ __html: el.modelNameMobile }} />
                    </div>
                ))}
            </div>
            
            <div className='x-models__carousel'>
                {xModel?.map((el, i) => {
                    const lastIndex = xModel.length - 1;
                    const prevIndex = i === 0 ? lastIndex : i-1;
                    const nextIndex = i === lastIndex ? 0 : i+1;

                    const prefix = el.modelNamePrefix ? `<span>${el.modelNamePrefix}</span>` : '<span style="opacity: 0">-</span>';

                    return (
                        <div key={i} className={`x-models__model x-models__model--${i} ${i === 0 ? 'active' : ''}`}>
                            <div className='x-models__copy-title x-models__copy-title--mobile'>
                                <Heading title={prefix + el.modelNameDesktop} />
                            </div>
                            <div className='x-models__model-head'>
                                <div className='x-models__arrow x-models__arrow--left' data-slide={prevIndex} />
                                <div className='x-models__model-head-inner'>
                                    <div className='x-models__copy-title x-models__copy-title--desktop'>
                                        <Heading title={prefix + el.modelNameDesktop} />
                                    </div>
                                    <div className='x-models__model-image'>
                                        {el.productColorVariations?.map((prod, c) => (
                                            <img 
                                                key={c}
                                                className={`x-models__color-image x-models__color-image--${c} ${c === 0 ? 'active-color' : ''}`}
                                                data-color={c} 
                                                src={prod.productImage.sourceUrl}
                                                alt={prod.productImage.altText} 
                                            />
                                        ))}
                                    </div>
                                    <div className='x-models__actions'>
                                        <div className='x-models__color-text' dangerouslySetInnerHTML={{ __html: xModelsColorText }} />
                                        <ul className='x-models__color-selector'>
                                            {el.productColorVariations?.map((prod, c) => (
                                                <li key={c} className={`x-models__color-icon x-models__color-icon--${c} ${c === 0 ? 'active-color' : '' }`} data-color={c} >
                                                    <img src={prod.productColorIcon.sourceUrl} alt={prod.productColorIcon.altText} />
                                                </li>
                                            ))}
                                        </ul>
                                        <ul className='x-models__nav-dots'>
                                            {xModel.map((model, k) => (
                                                <li key={k} className={`x-models__nav-dot x-models__nav-dot--${k} ${k === 0 ? 'active' : ''}`} data-slide={k} />
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className='x-models__arrow x-models__arrow--right'  data-slide={nextIndex}/>
                            </div>
                            <div className='x-models__copy-text' dangerouslySetInnerHTML={{ __html: el.modelCopyText }} />
                            <div className='x-models__features'>
                                {el.productFeatures?.map((feature, k) => (
                                    <div key={k} className='x-models__feature'>
                                        <div className='x-models__feature-icon' style={{backgroundImage: `url(${feature.featureIcon.sourceUrl})`}} />
                                        <div className='x-models__feature-text' dangerouslySetInnerHTML={{ __html: feature.featureName }} />
                                    </div>
                                ))}
                            </div>
                            <div className='x-models__cta'>
                                <Cta className='cta--horizon' ctaList={ctaList} ctaRef={el.modelCta.flexibleCta} />
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className='separator'/>
            
            <div className='x-models__bottom-buttons'>
                {xModel?.map((el, i) => (
                    <div key={i} className={`x-models__bottom-button x-models__bottom-button--${i} ${i === 0 ? 'active' : ''}`} data-slide={i} >
                        <div className='x-models__button-image'>
                            <img src={el.productColorVariations[0]?.productImage?.sourceUrl} alt={el.productColorVariations[0]?.productImage?.altText} />       
                        </div>

                        <div className='x-models__button-name' dangerouslySetInnerHTML={{ __html: el.modelNameDesktop }} />
                    </div>
                ))}
            </div>

        </section>
    )
}

export default xModels