import React from 'react'

const hero = ({ data, wpParent }) => {
    const parent = wpParent?.node?.TPLManufacturer
    const brandColour = parent ? (data.brandColour || parent.brandColour) : '#1f75c1' 
    const heroBackgroundImageDesktop = data.heroBackgroundImageDesktop || parent?.heroBackgroundImageDesktop
    const heroBackgroundImageMobile = data.heroBackgroundImageMobile || parent?.heroBackgroundImageMobile
    const heroProductLogo = data.heroProductLogo || parent?.heroProductLogo

    return (
        <section className='hero hero--general'>
            <div className='hero__background-cont'>
                <div className='hero__background'>
                    <picture>
                        <source srcSet={heroBackgroundImageDesktop?.sourceUrl}
                            alt={heroBackgroundImageDesktop?.altText} media='(min-width: 768px)'/>
                        <img loading='lazy' src={heroBackgroundImageMobile?.sourceUrl}
                            alt={heroBackgroundImageMobile?.altText} />
                    </picture>
                </div>
            </div>

            {heroProductLogo && (
                <div className='hero__logo-wrapper'>
                    <div className='hero__logo-image'>
                        <img loading='lazy' src={heroProductLogo.sourceUrl}
                            alt={heroProductLogo.altText} />
                    </div>
                </div>
            )}

            <div className='hero__triangles'>
                <div className='hero__triangles-over'>
                    <div className='hero__triangle-left'>
                        <div className='hero__triangle-left-pseudo-general'></div>
                    </div>
                    <div className='hero__triangle-right '>
                        <div className='hero__triangle-right-pseudo-general'>
                            <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                                <defs>
                                    <linearGradient id='default-gradient'  gradientTransform='rotate(0)'>
                                        <stop offset='0%' stopColor='rgba(255,255,255,.7)' ></stop>
                                        <stop offset='40%' stopColor='rgba(255,255,255,0)'></stop>
                                        <stop offset='50%' stopColor='rgba(0,0,0,0)'></stop>
                                        <stop offset='100%' stopColor='rgba(0,0,0,.5)'></stop>
                                    </linearGradient>
                                </defs>
                                <polygon points='0, 100 100,100 100,0' fill={brandColour} ></polygon>
                                <polygon points='0, 100 100,100 100,0' fill='url(#default-gradient)' ></polygon>
                            </svg>
                        </div>
                    </div>  
                </div>
                <div className='hero__triangle-shadow-under'></div>
                <div className='hero__triangle-under'>
                    <div className='hero__triangle-under-pseudo-general'></div>
                </div>
            
            </div>
        </section>
    )
}

export default hero