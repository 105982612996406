export const throttle = (callback, delay) => {
    let timeoutHandler = null;

    return function () {
        if (timeoutHandler == null) {
        timeoutHandler = setTimeout(() => {
            callback();
            clearInterval(timeoutHandler);
            timeoutHandler = null;
        }, delay);
        }
    };
};


export const smoothScrollTo = (target) => {
    target.scrollIntoView({behavior: "smooth", block: "start"});
}

export const copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }