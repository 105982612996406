import React from 'react'
import Heading from './Heading'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import Cta from './Cta'

const intro = ({ data, pageContext, pageTitle , ctaList}) => {
    const {
        featuredTitle,
        featuredSubtitle,
        featuredMainText,
        featuredHearingAidDevice,
        featuredBlockLeft,
        featuredBlockRight,
        disableFlexibleCta,
        flexibleCta
    } = data

    const { breadcrumb } = pageContext;
    const crumbs = breadcrumb.crumbs.map(crumb => {
        return {
            pathname: crumb.pathname,
            crumbLabel: crumb.crumbLabel.replace('-', ' ')
        }
    })

    return (
        <section className='intro'>
            <div className='breadcrumbs__wrapper'>
                <Breadcrumb crumbs={crumbs} crumbLabel={pageTitle} crumbSeparator='›' />
            </div>
            <div className='intro__heading'>
                <Heading title={featuredTitle} subtitle={featuredSubtitle} />
            </div>
            
            <div className='intro__main-text' dangerouslySetInnerHTML={{ __html: featuredMainText }} />
            <div className='intro__left-side'>
                <div className='intro__image'>
                    {featuredHearingAidDevice && (
                        <img src={featuredHearingAidDevice.sourceUrl} alt={featuredHearingAidDevice.altText} />
                    )}
                </div>
            </div>

            <div 
                className='intro__text-block intro__text-block--left' 
                dangerouslySetInnerHTML={{ __html: featuredBlockLeft }} />
            <div 
                className='intro__text-block intro__text-block--right' 
                dangerouslySetInnerHTML={{ __html: featuredBlockRight }} />
            
            
            {!disableFlexibleCta && (
                <div className='intro__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}
            <div className='separator'></div>
            
        </section>
    )
}

export default intro