import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const splitText = ({ data, ctaList }) => {
    const {
        bottom,
        date,
        flexibleCta,
        location,
        sectionId,
        subtitle,
        textRight,
        textLeft,
        title
    } = data
 
    return (
        <section id={`id-${sectionId}`} className='split-text split-text--press'>

            <div className='split-text__heading'>
                <Heading title={title} subtitle={subtitle} />
            </div>

            {(date || location) && (
                <div className='split-text__location-date'>
                    <span className='split-text__location' dangerouslySetInnerHTML={{ __html: location }} />

                    <span className='split-text__date' dangerouslySetInnerHTML={{ __html: date }} />
                </div>
            )}

            <div className='split-text__column'>
                <div className='split-text__text' dangerouslySetInnerHTML={{ __html: textLeft }} />
            </div>


            <div className='split-text__column'>
                <div className='split-text__text' dangerouslySetInnerHTML={{ __html: textRight }} />
            </div>

            {(bottom === 'cta' || bottom === 'cta-separator') && (
                <div className='split-text__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}

            {(bottom === 'separator' || bottom === 'cta-separator') && (
                <div className='separator'/>
            )}
        </section>
    )
}

export default splitText