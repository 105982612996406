import React from 'react'

const author = ({ authorList, authorRef }) => {
    const { authors }  = authorList
    const author = authors?.filter(author => author.authorId === authorRef)[0];
    const { authorName, links, profileImage, textAboveName, textUnderName } = author

    return (
        <div className='author'>
            <div className='author__image' style={{backgroundImage: `url(${profileImage?.sourceUrl})`}} />

            <div className='author__copy-text'>

                <div className='author__headline' dangerouslySetInnerHTML={{ __html: textAboveName }} />
                <div className='author__name' dangerouslySetInnerHTML={{ __html: authorName }} />
                <div className='author__bottomline'>
                    <span dangerouslySetInnerHTML={{ __html: textUnderName }} />&nbsp;25.03.2021
                    {/* TODO: Last modified date algorithm */}
                </div>

                <div className='author__social-icons'>
                    {links?.map((link, i) => (
                        <a key={i} className={`author__icon author__icon--${link.icon}`} href={link.link} title={link.icon} target='_blank' rel='noreferrer'></a>
                    ))}
                </div>

            </div>
        </div>
    )

}

export default author