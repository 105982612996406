import React from 'react'
import Heading from './Heading'

const about = ({ data }) => {
    const {
        aboutTitle,
        aboutText,
        brandColour
    } = data

    return (
        <section className='about-app' style={{backgroundColor: brandColour}} >
            <div className='about-app__container'>
                <div className='about-app__heading'>
                    <Heading title={aboutTitle} />
                </div>
            
                <div className='about-app__wrapper'>
                <div className='about-app__text' dangerouslySetInnerHTML={{ __html: aboutText }} />
                </div>
            </div>
        </section>
    )
}

export default about