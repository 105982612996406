import React from 'react'

const slant = ({ color }) => {

    return (
        <div className='slant'>
            <div className='slant__shape' style={{backgroundColor: color}} ></div>
        </div>
    )

}

export default slant