import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro'
import PressCards from '../components/FlexibleContent/PressCards'

const Press = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    TPLPress,
    wpParent,
    seo,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    headerSettings: { OPTHeaderSettings }
  } = options


  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-press'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLPress} wpParent={wpParent} />
        <Intro data={TPLPress} ctaList={OPTCTAButtons} pageContext={pageContext} pageTitle={title} />
        <PressCards data={TPLPress} />
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default Press