import React from 'react'

const hero = ({ data, isFrontPage }) => {
    const {
        headingFirstRow,
        headingSecondRow,
        heroBackgroundImageDesktop,
        heroBackgroundImageMobile,
        heroOverImageDesktop,
        heroOverImageMobile
    } = data

    return (
        <section className='hero'>
            <div className='hero__background-cont'>
                <div className='hero__background'>
                    <picture>
                        <source srcSet={heroBackgroundImageDesktop.sourceUrl}
                            alt={heroBackgroundImageDesktop.altText} media='(min-width: 768px)'/>
                        <img loading='lazy' src={heroBackgroundImageMobile.sourceUrl}
                            alt={heroBackgroundImageMobile.altText} />
                    </picture>
                </div>
                
                <div className='hero__main-image'>
                    <picture>
                        <source srcSet={heroOverImageDesktop.sourceUrl}
                            alt={heroOverImageDesktop.sourceUrl} media='(min-width: 576px)'/>
                        <img loading='lazy' className='hero__main-image-inner' src={heroOverImageMobile.sourceUrl}
                            alt={heroOverImageMobile.altText} />
                    </picture>
                </div>
            </div>

            <div className='hero__wrapper'>
                <h1 className='hero__heading'>
                    <span 
                        className='hero__title hero__title--animated'
                        dangerouslySetInnerHTML={{ __html: headingFirstRow }}
                        />

                    <span 
                        className={`hero__title hero__title--second hero__title--animated ${isFrontPage ? 'hero__title--homepage' : ''}`}
                        dangerouslySetInnerHTML={{ __html: headingSecondRow }}
                        />
                </h1>
            </div>

            <div className='hero__triangles'>
                <div className='hero__triangle-shadow-over-wrapper'>
                        <div className='hero__triangle-shadow-over' alt='shadow-top triangle'/>
                </div>
                <div className='hero__triangles-over'>
                    <div className='hero__triangle-left'>
                        <div className='hero__triangle-left-pseudo hero__triangle--animated'/>
                    </div>
                    <div className='hero__triangle-right'>
                        <div className='hero__triangle-right-pseudo hero__triangle--animated'/>
                    </div>
                </div>
                <div className='hero__triangle-shadow-under'></div>
                <div className='hero__triangle-under'>
                    <div className='hero__triangle-under-pseudo hero__triangle--animated'/>
                </div>
            
            </div>
        </section>
    )
}

export default hero