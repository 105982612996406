import React from 'react'

const Form = ({ formList, data }) => {
    const { pageQuestionnaire, questionnaireTitle }  = data
    const { forms }  = formList
    const form = forms && forms.filter(form => form.formUniqueId === pageQuestionnaire)[0];
    const { formUuid, formTitle } = form;

    return (
        <div className='form'>
            <div className='form__wrapper form__wrapper--cta form__wrapper--fixed'>
                <div className='form__container' data-id={formUuid} data-title={questionnaireTitle ? questionnaireTitle : ''}>
                    <div className='cta-simulator cta-simulator--has-form'>
                        <div className='cta__slant'/>
                        <div className='cta__text'>
                            <span dangerouslySetInnerHTML={{ __html: formTitle }} />
                            <span className='cta__arrow'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Form