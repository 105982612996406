import React from 'react'

const cta = ({ ctaList, ctaRef, className = '' }) => {
    const { ctaButtons }  = ctaList
    const cta = ctaButtons?.filter(cta => cta.ctaUniqueId === ctaRef)[0] || {};
    let ctaAction = {};
    
    switch(cta.ctaAction) {
        case 'page-questionnaire':
            ctaAction = {
                action: 'page-questionnaire',
                type: 'form'
            }
            break
        case 'page':
            ctaAction = {
                action: cta.ctaLinkPage.link,
                type: 'link'
            }
            break
        default:
            ctaAction = {
                action: cta.ctaLinkCustom,
                type: 'link'
            }
            break
    }

    return (
        <>
            <div className={`cta ${className}`} data-action={ctaAction.action} data-type={ctaAction.type}>
               <div className='cta__slant'/>
                {ctaAction.type === 'link' ? (
                    <a href={ctaAction.action}>
                        <div className='cta__text'>
                            {cta.ctaText}
                            <span className='cta__arrow'/>
                        </div>
                    </a>
                ) : (
                    <div className='cta__text'>
                        {cta.ctaText}
                        <span className='cta__arrow'/>
                    </div>
                )}
            </div>

            <div className='form__wrapper form__wrapper--cta'/>
        </>
    )

}

export default cta