import React from 'react'
import Heading from './Heading'

const framedSteps = ({ data }) => {
    const {
        soundStepsTitle,
        soundStepsSubtitle,
        framedStepsText
    } = data

    const { step1, step2, step3, step4 } = framedStepsText
 
    return (
        <section className='framed-steps'>
            <div className='framed-steps__wrapper'>
                <div className='framed-steps__heading'>
                    <Heading title={soundStepsTitle} subtitle={soundStepsSubtitle} />
                </div>

                <div className='framed-steps__steps'>
                    <div className='framed-steps__step framed-steps__step--1'>
                        <div className='framed-steps__step__number'>
                            1
                        </div>
                        <div className='framed-steps__step__body'>
                            <p className='framed-steps__step__text' dangerouslySetInnerHTML={{ __html: step1 }} />
                        </div>
                    </div>
                    <div className='framed-steps__step framed-steps__step--2'>
                        <div className='framed-steps__step__number'>
                            2
                        </div>
                        <div className='framed-steps__step__body'>
                            <p className='framed-steps__step__text' dangerouslySetInnerHTML={{ __html: step2 }} />
                        </div>
                    </div>
                    <div className='framed-steps__step framed-steps__step--3'>
                        <div className='framed-steps__step__number'>
                            3
                        </div>
                        <div className='framed-steps__step__body'>
                            <p className='framed-steps__step__text' dangerouslySetInnerHTML={{ __html: step3 }} />
                        </div>
                    </div>
                    <div className='framed-steps__step framed-steps__step--4'>
                        <div className='framed-steps__step__number'>
                            4
                        </div>
                        <div className='framed-steps__step__body'>
                            <p className='framed-steps__step__text' dangerouslySetInnerHTML={{ __html: step4 }} />
                        </div>
                    </div>
                </div>

                <div className='separator'></div>
            </div>
        </section>
    )
}

export default framedSteps