import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

const HoerenHeute = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    TPLPress,
    wpParent,
    seo,
    title,
    content
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    headerSettings: { OPTHeaderSettings }
  } = options


  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-hoeren-heute'>
        <Header data={OPTHeaderSettings} />
        <div className='page'>
          <div dangerouslySetInnerHTML={{__html: content }} />
        </div>
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default HoerenHeute