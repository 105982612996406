import React from 'react'
import Slant from './Slant'
import Heading from './Heading'

const cards = ({ data }) => {
    const { cards, title, subtitle } = data
 
    return (
        <section className='cards'>
            {title || subtitle ? (
                <div className='cards__heading'>
                    <Heading title={title} subtitle={subtitle} />
                </div>
            ) : ''}
            {cards?.map((card, i) => {
                const link = card.linkType === 'page' ? card.page.link : card.url
                const backgroundImage = card.style === 'overlap' ? card.backgroundImage : card.cardImage

                return (
                    <article key={i} className={`card card--${card.style}`} style={{backgroundImage: `url(${card.style !== 'overlap' ? card.cardImage.sourceUrl : ''})`}} >
                        <a href={link} className='card__link'>
                            <div className='card__top-clearfix' style={{backgroundImage: `url(${card.style !== 'full' ? backgroundImage.sourceUrl : ''})`}} >
                                {card.style === 'overlap' && (
                                    <>
                                        <img className='card__logo' src={card.logo} alt='' />
                                        <img src={card.cardImage.sourceUrl} alt={card.cardImage.altText} />
                                    </>
                                )}
                            </div>
                            <div className='card__body' style={{backgroundColor: card.cardColor}} >
                                <Slant color={card.cardColor} />
        
                                <h3 
                                    className='card__title' 
                                    style={{color: card.textColor}}
                                    dangerouslySetInnerHTML={{ __html: card.title }}
                                />
        
                                <p className='card__text' style={{color: card.textColor}} >
                                    <span dangerouslySetInnerHTML={{ __html: card.text }} />
                                    <span className={`card__arrow card__arrow--${card.cardArrowColor}`} />
                                </p>
                            </div>   
                        </a>
                    </article>
                )
            })}
        </section>
    )
}

export default cards