import React from 'react'
import Heading from '../Heading'

const battery = ({ data }) => {
    const {
        batteryTitle,
        batteryCopyTitle,
        batteryBgDesktop1,
        batteryBgDesktop2,
        batteryBgMobile1,
        batteryCopyImage,
        batteryCopyText,
        batteryCopyList
    } = data

    return (
        <section className='battery'>
            <div className='battery__head'>
                <div className='battery__image'>
                    <picture>
                        <source srcSet={batteryBgDesktop1.sourceUrl}
                            alt={batteryBgDesktop1.altText} media='(min-width: 768px)' />
                        <img loading='lazy' src={batteryBgMobile1.sourceUrl}
                            alt={batteryBgMobile1.altText} />
                    </picture>
                    <div className='battery__bullet bullet-parallax'></div>
                </div>
            
                <div className='battery__image'>
                    <img loading='lazy' src={batteryBgDesktop2.sourceUrl} alt={batteryBgDesktop2.altText} />
                </div>
            </div>
            <div className='battery__copy'>
                <h1 className='battery__heading' dangerouslySetInnerHTML={{ __html: batteryTitle }} />
                <div className='battery__content-wrapper'>
                    <div className='battery__copy-title'>
                        <Heading title={batteryCopyTitle} />
                    </div>
                    <div className='battery__content-image'>
                        <img src={batteryCopyImage.sourceUrl} alt={batteryCopyImage.altText} loading='lazy'/> 
                    </div>
                    <div className='battery__copy-text' dangerouslySetInnerHTML={{ __html: batteryCopyText }} />
                    <div className='battery__checklist' dangerouslySetInnerHTML={{ __html: batteryCopyList }} />
                </div>
            </div>
            
        </section>
    )
}

export default battery