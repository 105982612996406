import React from 'react'
import Flickity from 'react-flickity-component'
import Heading from './Heading'

const carousel = ({ data, carouselList, template }) => {
    const {
        associateCarousel,
        title
    } = data
    const { carousels } = carouselList
    const carousel =  carousels && carousels.filter(el => el.carouselUniqueId === associateCarousel)[0]
    const { products } = carousel

    const flickityOptions = {
        initialIndex: 1,
        pageDots: false,
        wrapAround: true,
        imagesLoaded: true,
        lazyLoad: 3,
        arrowShape: {
            x0: 50,
            x1: 60,
            y1: 50,
            x2: 65,
            y2: 50,
            x3: 55,
        }
    }

    return (
        <section className={`carousel carousel--${template}`} >
            <div className='carousel__heading'>
                <Heading title={title} />
            </div>
            <div className={`carousel__wrapper carousel__wrapper--${carousel.productsPerView}`}>
            
            <Flickity
                className={'flickity-carousel'} // default ''
                elementType={'div'} // default 'div'
                options={flickityOptions} // takes flickity options {}
                disableImagesLoaded={false} // default false
                reloadOnUpdate // default false
                static // default false
            >
                {products?.map((item, i) => {
                    const action = item.productLink === 'url' ? item.productUrl : (item.productPage && item.productPage.link);
                    const carouselInner = (
                        <>
                            <div className='carousel__body'>
                                <img loading='lazy' className='carousel__image' src={item.productImage.sourceUrl}  alt={item.productImage.altText} />
                            </div>
                            <div className='carousel__bottom'>
                                <span className='carousel__model-name' dangerouslySetInnerHTML={{ __html: item.modelName }} />
                            </div>
                        </>
                    )
                    return (
                        <div className='carousel__item' key={i}>
                            {action ? (
                                <a href={action}>{carouselInner}</a>
                            ) : carouselInner}
                        </div>
                    )
                })}
            </Flickity>
                
            </div>
        </section>
    )
}

export default carousel