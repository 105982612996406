import React from 'react'
import Heading from './Heading'
import Slant from './Slant'

const pressCards = ({ data }) => {
    const {
        pressCardsTitle,
        pressCardsSubtitle,
        pressCards
    } = data

    return (
        <section className='press-cards'>
            <div className='press-cards__heading'>
                <Heading title={pressCardsTitle} subtitle={pressCardsSubtitle} />
            </div>

            <div className='press-cards__wrapper'>
                {pressCards?.map((card, i) => {
                    const { 
                        cardColor,
                        cardContactName,
                        cardContent,
                        cardImage,
                        cardTitle,
                        cardType,
                        email,
                        fax,
                        fieldGroupName,
                        link,
                        tel
                    } = card
                    return (
                        <article key={i} className={`press-card press-card--${cardType}`} style={{backgroundImage: `url(${cardImage?.sourceUrl})`}}>
                            <div className='press-card__top-clearfix'/>
                            <div className='press-card__body' style={{backgroundColor: cardColor }}>
                                <Slant color={cardColor} />
                                <div className='press-card__text-wrapper'>
                                    <h3 className='press-card__title' dangerouslySetInnerHTML={{ __html: cardTitle }} />

                                    {cardType === 'contact' ? (
                                        <div className='press-card__contact'>
                                            <div className='press-card__name' dangerouslySetInnerHTML={{ __html: cardContactName }} />
                                            <div className='press-card__telephone' dangerouslySetInnerHTML={{ __html: tel }} />
                                            <div className='press-card__fax' dangerouslySetInnerHTML={{ __html: fax }} />
                                            <div className='press-card__email' dangerouslySetInnerHTML={{ __html: email }} />
                                        </div>
                                    ) : (
                                        <div className='press-card__content' dangerouslySetInnerHTML={{ __html: cardContent }} />
                                    )}

                                    <a className='press-card__link' href={link?.url} title={link?.title} target={link?.target}>
                                        <span className='press-card__link-text' dangerouslySetInnerHTML={{ __html: link?.title }} />
                                    </a>
                                </div>
                        </div>
                    </article>            
                    )
                })}
            </div>
        </section>
    )
}

export default pressCards