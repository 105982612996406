import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro.prod'
import ProductFeatures from '../components/FlexibleContent/ProductFeatures'
import ImageText from '../components/FlexibleContent/ImageText'
import SplitText from '../components/FlexibleContent/SplitText'
import Carousel from '../components/FlexibleContent/Carousel'
import Form from '../components/FlexibleContent/Form'

const Product = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    CTRLPages, 
    TPLProductPage,
    wpParent,
    seo,
    template,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    forms: { OPTForms },
    headerSettings: { OPTHeaderSettings },
    productCarousels: { OPTProductCarousels }
  } = options

  const { productSections } = TPLProductPage

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'ProductFeatures':
        return <ProductFeatures data={data} key={index} ctaList={OPTCTAButtons} />

      case 'ImageText':
        return <ImageText data={data} template={template} key={index} ctaList={OPTCTAButtons} />
      
      case 'SplitText':
        return <SplitText data={data} template={template} key={index} ctaList={OPTCTAButtons} />

      case 'Carousel':
        return <Carousel data={data} key={index} carouselList={OPTProductCarousels} />
  
      default:
        return <p key={index}>{contentType}</p>
    }
  }

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-product'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLProductPage} wpParent={wpParent} />
        <Intro data={TPLProductPage} ctaList={OPTCTAButtons} pageContext={pageContext} pageTitle={title} ctaList={OPTCTAButtons} />

        {productSections && productSections.map((section, index) => {
          const contentType = section.fieldGroupName.replace('page_Tplproductpage_ProductSections_', '');
          return assignComponent(contentType, section, index);
        })}

        <Form data={CTRLPages} formList={OPTForms} />
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default Product