import React from 'react'
import Heading from './Heading'

const icons = ({ data }) => {
    const {
        bottom,
        flexibleCta,
        icons,
        infoIconsTitle,
        inforIconsSubtitle,
        itemsPerRow,
        sectionId = ''
    } = data

    return (
        <section id={`id-${sectionId}`} className={`info-icons info-icons--grid-${itemsPerRow}`} >
            <div className='img-text__heading'>
                <Heading title={infoIconsTitle} subtitle={inforIconsSubtitle} />
            </div>
            <div className='info-icons__icons-wrapper'>
                {icons?.map((icon, i) => (
                    <div key={i} className='info-icons__icon'>
                        <div className='info-icons__image'>
                            <img src={icon.iconImage.sourceUrl} alt={icon.iconImage.altText} loading='lazy' />
                        </div>
                        <div className='info-icons__label' dangerouslySetInnerHTML={{ __html: icon.iconLabel }} />
                    </div>
                ))}
            </div>    
        </section>
    )
}

export default icons