import React from 'react'

const Footer = ({data, template, pageLogo}) => {
    const { 
        footerLogo, 
        footerMenuItems, 
        footerPhoneNumber,
        internationalFlags,
        internationalTitle,
        mediaIconsDesktop,
        mediaIconsMobile,
        mediaTitle,
        openingHours,
        socialIcons,
        socialTitle,
        trustIconsDesktop,
        trustIconsMobile
    } = data;

	return (
		<footer className='footer'>
            <div className='footer__wrapper'>
                <div className='footer__area footer__area--top'>
                    <div className='footer__logo'>
                        {template?.templateName && template.templateName === 'Horizon' ? (
                            <img loading='lazy' src={pageLogo} />
                        ) : (
                            <img loading='lazy' src={footerLogo} alt='logo' />
                        )}
                    </div>
                    <div className='footer__top-clearfix'/>
                    <div className='footer__contact'>
                        <a title='phone' href={footerPhoneNumber.url} className='footer__contact-icon'/>
                        <div className='footer__phone-hours'>
                            <a 
                                className='footer__phone' 
                                title='phone' 
                                href={footerPhoneNumber.url} 
                                dangerouslySetInnerHTML={{ __html: footerPhoneNumber.title }}
                            />
            
                            <span className='footer__hours' dangerouslySetInnerHTML={{ __html: openingHours }} />
                        </div>
                    </div>
                </div>
            
                <div className='footer__area footer__area--middle'>
                    <ul className='footer__menu'>
                        {footerMenuItems && footerMenuItems.map((item, i) => (
                            <li key={i} className='footer__menu-item'>
                                <a
                                    href={item.type === 'custom' ? item.linkCustom : (item.linkPage && item.linkPage.link)}
                                    dangerouslySetInnerHTML={{ __html: item.text }} 
                                    />
                            </li>
                        ))}
                    </ul>
                    <div className='footer__social'>
                        <div className='social__inner'>
                            <div className='social__title' dangerouslySetInnerHTML={{ __html: socialTitle }} />
                            <div className='social__icons'>
                                {socialIcons && socialIcons.map((icon, i) => (
                                    <a key={i} className='social__icon' href={icon.url} rel='me'>
                                        <img loading='lazy' src={icon.icon.sourceUrl} alt={icon.icon.altText} />
                                    </a>
                                ))}
                            </div>
                        </div>

                    </div>

                    <div className='footer__world'>
                        <div className='footer__world-title' dangerouslySetInnerHTML={{ __html: internationalTitle }} />
                        <div className='footer__world-flags'>
                            {internationalFlags && internationalFlags.map((flag, i) => (
                                <a key={i} className='flag' title='audibene worldwide' href={flag.url}>
                                    <img loading='lazy' src={flag.flag.sourceUrl} alt={flag.flag.altText} />
                                </a>
                            ))}
                        </div>
                    </div>
                    
                </div>
            
                <div className='footer__area footer__area--bottom'>
                    <div className='press__title' dangerouslySetInnerHTML={{ __html: mediaTitle }} />

                    <div className='footer__bottom-wrapper'>
                        <div className='press'>
                            {mediaIconsMobile && (
                                <picture className='media__icons'>
                                    <source srcSet={mediaIconsDesktop.sourceUrl}
                                        alt={mediaIconsDesktop.altText} media='(min-width: 768px)' />
                                    <img loading='lazy' src={mediaIconsMobile.sourceUrl}
                                        alt={mediaIconsMobile.altText} />
                                </picture>        
                            )}
                        </div>
                    </div>
                </div>

                <div className='footer__area footer__area--bottom'>

                    <div className='footer__bottom-wrapper'>            
                        <div className='trust'>
                            {trustIconsDesktop && (
                                <picture className='trust__icons'>
                                    <source srcSet={trustIconsDesktop.sourceUrl}
                                        alt={trustIconsDesktop.altText} media='(min-width: 768px)' />
                                    <img loading='lazy' src={trustIconsMobile.sourceUrl}
                                        alt={trustIconsMobile.altText} />
                                </picture>
                            )}
            
                        </div>
                    </div>
                </div>
            </div>
            
        </footer>
	)

}

export default Footer