import React from 'react'

const hero = ({ data }) => {
    const brandColour = data.brandColour || '#1f75c1' 
    const heroBackgroundImageDesktop = data.heroBackgroundImageDesktop
    const heroBackgroundImageMobile = data.heroBackgroundImageMobile
    const heroProductLogo = data.heroProductLogo

    return (
        <section className='hero hero--general'>
            <div className='hero__background-cont'>
                <div className='hero__background'>
                    <picture>
                        <source srcSet={heroBackgroundImageDesktop?.sourceUrl}
                            alt={heroBackgroundImageDesktop?.altText} media='(min-width: 768px)'/>
                        <img loading='lazy' src={heroBackgroundImageMobile?.sourceUrl}
                            alt={heroBackgroundImageMobile?.altText} />
                    </picture>
                </div>
            </div>

            {heroProductLogo && (
                <div className='hero__logo-wrapper'>
                    <div className='hero__logo-image'>
                        <img loading='lazy' src={heroProductLogo.sourceUrl}
                            alt={heroProductLogo.altText} />
                    </div>
                </div>
            )}

            <div className='hero__triangles'>
                <div className='hero__triangles-over'>
                    <div className='hero__triangle-left'>
                        <div className='hero__triangle-left-pseudo-general'></div>
                    </div>
                    <div className='hero__triangle-right '>
                        <div className='hero__triangle-right-pseudo-general'>
                            <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 243.4 42.88' style={{enableBackground: 'new 0 0 243.4 42.88'}} preserveAspectRatio='none'>
                                    <linearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='0.357' y1='417.3394' x2='1.295' y2='417.8394' gradientTransform='matrix(243.4 0 0 -42.883 -71.797 17939.6465)'>
                                        <stop  offset='0' stopColor='#6EB0E0'/>
                                        <stop  offset='1' stopColor='#1B2441'/>
                                    </linearGradient>
                                <path className='st0' d='M0,42.88L243.4,0v42.88H0z' style={{fill: 'url(#SVGID_1_)'}}/>
                            </svg>
                        </div>
                    </div>  
                </div>
                <div className='hero__triangle-shadow-under'></div>
                <div className='hero__triangle-under'>
                    <div className='hero__triangle-under-pseudo-general'>
                        <svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                            <defs>
                                <linearGradient id='blue-gradient'  gradientTransform='rotate(90)'>
                                    <stop offset='0%' stopColor='rgba(0,91,157,1)' ></stop>
                                    <stop offset='100%' stopColor='rgba(0,145,215,1)' ></stop>
                                </linearGradient>
                            </defs>
                            <polygon points='0,0 0, 100 100,0' fill={brandColour}></polygon>
                        </svg>
                    </div>
                </div>
            
            </div>
        </section>
    )
}

export default hero