import React from 'react'
import Heading from '../Heading'
import Cta from '../Cta'

const experience = ({ data, ctaList }) => {
    const {
        brandVariation,
        experienceTitle,
        experienceSubtitle,
        experienceCta,
        experienceText,
        experienceGridImage1,
        experienceGridImage2,
        experienceGridImage3
    } = data

    return (
        <section className='experience section-has-cta'>
            <div className='experience__wrapper'>
                <div className='experience__head'>
                    
                    <div className='experience__heading'>
                        <Heading title={experienceTitle} subtitle={experienceSubtitle} />
                    </div>
                </div>
                <div className='experience__body'>
                    <div className='experience__content'>
                        <div className='experience__text' dangerouslySetInnerHTML={{ __html: experienceText }} />
                        {brandVariation !== 'b2b' && (
                            <div className='experience__cta'>
                                <Cta className='cta--horizon' ctaList={ctaList} ctaRef={experienceCta.flexibleCta} />
                            </div>
                        )}
                    </div>
                    <div className='experience__images'>
                        <div className='experience__images'>
                            <div className='experience__grid-img'>
                                <img loading='lazy' className='experience__grid-img-image' src={experienceGridImage1.sourceUrl} alt={experienceGridImage1.altText} />
                            </div>
                            <div className='experience__grid-img'>
                                <img loading='lazy' className='experience__grid-img-image' src={experienceGridImage2.sourceUrl} alt={experienceGridImage2.altText} />
                            </div>
                            <div className='experience__grid-img'>
                                <img loading='lazy' className='experience__grid-img-image' src={experienceGridImage3.sourceUrl} alt={experienceGridImage3.altText} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default experience