import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const productsGrid = ({ data, ctaList }) => {
    const {
        bottom,
        flexibleCta,
        productsCta,
    } = data

    const title = data.title || data.imageGridTitle
    const subtitle = data.subtitle || data.imageGridSubtitle
    const products = data.products || data.images

    return (
        <section className='products-grid'>
            <div className='products-grid__heading'>
                <Heading title={title} subtitle={subtitle} />
            </div>

            <div className='products-grid__wrapper'>
                {products?.map((product, i) => (
                    <a key={i} className='products-grid__product' href={product.link?.link}>
                        <div className='products-grid__img'>
                            <img src={product.image?.sourceUrl} alt={product.image?.altText} />
                            <div className='products-grid__triangle products-grid__triangle--left'>
                                <svg width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                                    <polygon points='100, 0 0,100 0,0'></polygon>
                                </svg>
                            </div>
                            <div className='products-grid__triangle products-grid__triangle--right'>
                                <svg width='100%' height='100%' viewBox='0 0 100 100' overflow='visible' preserveAspectRatio='none'>
                                    <polygon points='0,0 100,0 100,100'></polygon>
                                </svg>
                            </div>
                        </div>
                        <p className='products-grid__name' dangerouslySetInnerHTML={{ __html: product.name }} />
                    </a>
                ))}
            </div>

            {productsCta && !productsCta.disableFlexibleCta && (
                <div className='products-grid__cta'>
                    <Cta ctaList={ctaList} ctaRef={productsCta.flexibleCta} />
                </div>
            )}

            {productsCta && (
                <div className='separator' />
            )}

            {bottom && (bottom === 'cta' || bottom === 'cta-separator') && (
                <div className='products-grid__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}

            {bottom && (bottom === 'separator' || bottom === 'cta-separator') && (
                <div className='separator'/>
            )}
        </section>
    )
}

export default productsGrid