import { smoothScrollTo } from './utils'

export const ctaForm = () => {
	const buildQuestionnaire = (target) => {
		const questionnaire = document.createElement('script');
			  questionnaire.id = 'formclient';
			  questionnaire.src = 'https://formclient.cdn.audibene.net/v2/js/app.js';
			  questionnaire.classList.add('ClientInjector');
			  questionnaire.dataset.cl = 'v2';
			  questionnaire.dataset.id = target.dataset.id;
			  target.dataset.title && (questionnaire.dataset.title = target.dataset.title);
			  questionnaire.dataset.offerType = 'generic';
			  questionnaire.dataset.slideEnabled = 'true';
			  questionnaire.dataset.theme = 'webapp-audibene';
	  
					  target.appendChild(questionnaire);
	  }
	  
	const formSectionContainer = document.querySelector('.form .form__container');
		formSectionContainer && buildQuestionnaire(formSectionContainer)
	
	const ctas = document.querySelectorAll('.cta');
	const formSection = document.querySelector('.form');
	const SliderOverlay = '<div class="Slider-overlay"></div>';

	let formMoved = 0;
	
	window.addEventListener('FormClientRendered', () => {
		handleCtaClick();
	});

	function findParent(el, cls) {
		while ((el = el.parentElement) && !el.classList.contains(cls));
		return el;
	}
	  
	const handleCtaClick = () => {
		const isHorizon = document.body.classList.contains('page-template-horizon');
		
		ctas && ctas.forEach((cta) => {
			if (cta.dataset.type === 'form') {
			cta?.addEventListener('click', () => {
				const parentSection = findParent(cta, 'section-has-cta');

				document.querySelector('.section-has-cta--active') && document.querySelector('.section-has-cta--active').classList.remove('section-has-cta--active');
				if(!cta.classList.contains('cta--has-form')) {
				resetCtas();
				// add class to active cta
				cta.classList.add('cta--has-form')

				isHorizon && parentSection && parentSection.classList.add('section-has-cta--active');
				// place fordm
				placeForm(cta.nextElementSibling, true, false);
				smoothScrollTo(cta.nextElementSibling);
				
				} else {
				cta.classList.remove('cta--has-form');
				isHorizon && parentSection && parentSection.classList.remove('section-has-cta--active');
				placeForm(formSectionContainer, true, false);
				}
				// form has been moved
				formMoved = 1;
				//check when user scrolls back to regular form section
				window.addEventListener('scroll', bringBackForm);
			});
			}
		});
		document.querySelector('.Slider').insertAdjacentHTML('afterbegin',SliderOverlay);
	}
	
	const placeForm = (targetContainer, isCta, isForm) => {
		const formElement = document.querySelector('#questionnaire-wrapper');
	
		// remove cta active class
		if(!isCta) {
		  resetCtas();
		}
		
		if(isForm) {
			targetContainer.appendChild(formElement);
		} else {
			targetContainer.insertBefore(formElement, targetContainer.childNodes[0]);
		} 
	
		//place form in new container
		formElement.style.width = '100%';
	  }
	
	const bringBackForm = () => {
		if(formSection.getBoundingClientRect().top - window.innerHeight < 200) {
			// place form
			document.querySelector('.section-has-cta--active') && document.querySelector('.section-has-cta--active').classList.remove('section-has-cta--active');
			placeForm(formSectionContainer, false, true);
			// form back to initial position
			formMoved = 0;
			//stop event listener until next click on CTA
			window.removeEventListener('scroll', bringBackForm);
		}
	}
	const resetCtas = () => {
		document.querySelectorAll('.cta--has-form').forEach((activeCta) => {
			!activeCta.classList.contains('cta--ignore') && activeCta.classList.remove('cta--has-form');
		})
	}
	  
	function flattenData(buffer) {
		var result = new Array();
		if (!buffer) {
			return result;
		}
		buffer.map(function (item, index) {
			if ('object' !== typeof item || 'undefined' === typeof item || Array.isArray(item)) {
				return item;
			} else {
				Object.keys(item).map(function (val, ind) {
					this.response[val] = this.item[val];
				}, { item: item, response: result });
			}
		});
		return result;
}
		
	function writeCookie(name, value) {
		var date = new Date();
		date.setTime(date.getTime() + (1 * 1 * 60 * 60 * 1000));
		var expires = ';expires=' + date.toUTCString();
		var domain = 'domain=.audibene.de';
		window.cookie = name + '=' + value + ';' + expires + ';' + domain + ';path=/';
	}

	window.addEventListener('lastStepQuestionnaire', function () {
		console.log('last step')
	});	  
}