import React from 'react'
import Heading from './Heading'

const threeColumnBlocks = ({ data }) => {
    const {
        fieldGroupName,
        title,
        subtitle,
        blocks
    } = data

    return (
        <section className='three-column-blocks'>
            <div className='three-column-blocks__heading'>
                <Heading title={title} subtitle={subtitle} />
            </div>

            <div className='three-column-blocks__wrapper'>
                {blocks?.map((block, i) => (
                    <div key={i} className='three-column-blocks__block'>
                        <div className='three-column-blocks__img'>
                            <img src={block?.image.sourceUrl} alt={block?.image.altText} />
                        </div>
                        <h3 className='three-column-blocks__title' dangerouslySetInnerHTML={{ __html: block.title }} />
                        <p className='three-column-blocks__text' dangerouslySetInnerHTML={{ __html: block.text }} />
                    </div>
                ))}
            </div>
                
        </section>
    )
}

export default threeColumnBlocks