import React from 'react'
import Heading from './Heading'

const pressArchiveGroup = ({ data }) => {
    const {
        title,
        subtitle,
        type,
        items
    } = data

    return (
        <section className='press-archive'>
            <div className='press-archive__heading'>
                <Heading title={title} subtitle={subtitle} />
            </div>

            <div className='press-archive__wrapper'>
                {items?.map((item, i) => {
                    const { date, excerpt, title, image, cta } = item
                    return (
                        <div key={i} className='press-archive__item'>
                            <div className='press-archive__img'>
                                <img src={image?.sourceUrl} alt={image?.altText} />
                            </div>
                            <div className='press-archive__body'>
                                <div className={`press-archive__date press-archive__date--${date ? 'has-date' : 'no-date'}`} dangerouslySetInnerHTML={{ __html: date }}  />

                                <h3 className='press-archive__title' dangerouslySetInnerHTML={{ __html: title }} />

                                <p className='press-archive__excerpt'dangerouslySetInnerHTML={{ __html: excerpt }} />

                                {cta && (
                                    <a className='press-archive__link' href={cta.url} title={cta.title} target={cta.target} dangerouslySetInnerHTML={{ __html: cta.title }} />
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        </section>
    )
}

export default pressArchiveGroup