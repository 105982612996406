import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const steps = ({ data, ctaList }) => {
    const {
        flexibleCta,
        steps,
        text,
        title,
    } = data
 
    return (
        <section className='steps'>

            <div className='steps__heading'>
                <Heading title={title} />
            </div>

            <div className='steps__wrapper'>
                {steps && steps.map((step, i) => (
                    <div key={i} className={`step steps__step--${step.number}`}>
                        <div className='step__img' style={{backgroundImage: `url(${step.image.sourceUrl})`}}>
                            <span>{step.number}</span>
                        </div>

                        <div className='step__body'>
                            <div 
                                className='step__title' 
                                style={{color: step.color}} 
                                dangerouslySetInnerHTML={{ __html: step.title }} />

                            <div className='step__text'>
                                <p dangerouslySetInnerHTML={{ __html: step.text}}/>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <div className='steps__end-text' dangerouslySetInnerHTML={{ __html: text }}/>   

            <div className='steps__cta'>
                <Cta ctaList={ctaList} ctaRef={flexibleCta} />
            </div>
        </section>
    )
}

export default steps