import React from 'react'
import scrollArrow from '../../../assets/images/horizon/scroll-arrow.svg'

const hero = ({ data, isFrontPage }) => {
    const {
        heroHeading,
        heroHearingAidImage,
        heroBackgroundImageDesktop,
        heroBackgroundImageMobile
    } = data

    return (
        <section className='hero-horizon'>
            <div className='hero-horizon__background'>
                <picture>
                    <source srcSet={heroBackgroundImageDesktop.sourceUrl} alt={heroBackgroundImageDesktop.altText} media='(min-width: 768px)'/>
                    <img src={heroBackgroundImageMobile.sourceUrl} alt={heroBackgroundImageMobile.altText} />
                </picture>
            </div>

            <div className='hero-horizon__wrapper'>
                <div className='hero-horizon__heading'>
                    <div className='hero-horizon__title-wrapper'>
                        <h1 className='hero-horizon__title'>
                            <span className='hero-horizon__title-container'>
                                <span dangerouslySetInnerHTML={{ __html: heroHeading.title }} />
                                <img src={heroHearingAidImage.sourceUrl} alt={heroHearingAidImage.altText} />
                            </span>
                        </h1>
                    </div>
                    <div className='hero-horizon__subtitle-wrapper'>
                        <h2 className='hero-horizon__subtitle' dangerouslySetInnerHTML={{ __html: heroHeading.subtitle }} />
                    </div>
                </div>
            </div>
            <div className='hero-horizon__scroll'>
                <img src={scrollArrow} alt='scroll arrow' />
            </div>
        </section>
    )
}

export default hero