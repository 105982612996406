import React from 'react'
import Heading from './Heading'
import Cta from './Cta'

const splitText = ({ data, ctaList }) => {
    const {
        sectionId = '',
        bottom,
        subtitleLeft,
        flexibleCta,
        subtitleRight,
        textLeft,
        textRight,
        titleLeft,
        titleRight,
    } = data
 
    return (
        <section id={`id-${sectionId}`} className='split-text'>

            <div className='split-text__column split-text__column--heading'>
                {(titleLeft || subtitleLeft) && (
                    <div className='split-text__heading'>
                        <Heading title={titleLeft} subtitle={subtitleLeft} />
                    </div>
                )}
            </div>

            <div className='split-text__column split-text__column--body'>
                <div className='split-text__text' dangerouslySetInnerHTML={{ __html: textLeft }} />
            </div>

            <div className='split-text__column split-text__column--heading'>
                {(titleRight || subtitleRight) && (
                    <div className='split-text__heading'>
                        <Heading title={titleRight} subtitle={subtitleRight} />
                    </div>
                )}
            </div>

            <div className='split-text__column split-text__column--body'>
                <div className='split-text__text' dangerouslySetInnerHTML={{ __html: textRight }} />
            </div>

            {(bottom === 'cta' || bottom === 'cta-separator') && (
                <div className='split-text__cta'>
                    <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                </div>
            )}

            {(bottom === 'separator' || bottom === 'cta-separator') && (
                <div className='separator'/>
            )}
        </section>
    )
}

export default splitText