import React from 'react'
import Cta from './Cta'

const showcaseText = ({ data, ctaList }) => {
    const {
        background,
        bottom,
        flexibleCta,
        quoteMarks,
        secondaryText,
        sectionId = '',
        text
    } = data

    const imageDesktop = data.imageDesktop || data.imageMobile
    const imageMobile = data.imageMobile || data.imageDesktop

    return (
        <section id={`id-${sectionId}`} className={`showcase-text showcase-text--${background} ${imageDesktop ? 'showcase-text--has-image' : ''} ${quoteMarks ? 'showcase-text--quote--marks' : ''}`}>
            <div className='showcase-text__wrapper'>
                {imageDesktop && (
                    <div className='showcase-text__image'>
                        <picture>
                            <source srcSet={imageDesktop.sourceUrl}
                                alt={imageDesktop.altText} media='(min-width: 768px)' />
                            <img  srcSet={imageMobile.sourceUrl}
                                alt={imageMobile.altText} />
                        </picture>
                    </div>
                )}
                <div className='showcase-text__container showcase-text__container--content'>
                    {text && (
                        <div className='showcase-text__text' dangerouslySetInnerHTML={{ __html: text }} />
                    )}
                    {secondaryText && (
                        <div className='showcase-text__secondary-text' dangerouslySetInnerHTML={{ __html: secondaryText }} />
                    )}
                </div>
            
            </div>
            <div className='showcase-text__container'>
                {(bottom === 'cta' || bottom === 'cta-separator') && (
                    <div className='showcase-text__cta'>
                        <Cta ctaList={ctaList} ctaRef={flexibleCta} />
                    </div>
                )}

                {(bottom === 'separator' || bottom === 'cta-separator') && (
                    <div className='separator' />
                )}
            </div>
        </section>
    )
}

export default showcaseText