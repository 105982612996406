import React from 'react'
import { Link } from "gatsby"

const submenu = ({menu, parentDatabaseId, level}) => {
    
    return (
        <div className={`submenu submenu--level-1 ${level > 1 ? 'menu-hide' : ''} submenu-${parentDatabaseId}`}>
            <div className='menu-list-wrapper'>
                <ul className='menu-list'>
                    {menu && menu.map((item, i) => {
                        const hasChildren = item.childItems.nodes.length > 0;
                        const classHasMegaMenu = hasChildren ? 'menu-item-has-children' : '';
                        return (
                            <div key={i} className='menu-group menu-group--level-1'>
                                <li className={`menu-item menu-item--level-1 menu-item-${item.databaseId} ${classHasMegaMenu}`} data-menu-id={item.databaseId} data-level='1'>
                                    {/* {item.label} */}
                                    <Link to={item.url} title={item.label}>
                                        {item.label}
                                    </Link>
                                </li>
                                <div className={`submenu submenu--level-2 menu-hide submenu-${item.databaseId}`}>
                                    {hasChildren && (
                                        <>
                                            <div className='menu-headline'>
                                                <Link to={item.url} title={item.label}>
                                                    {item.label}
                                                </Link>
                                            </div>
                                            <div className='menu-list-wrapper'>
												<ul className='menu-list'>
													{item.childItems.nodes.map((subItem, j) => {
														return (
															<li key={j} className={`menu-item menu-item--level-2 menu-item-${subItem.databaseId}`}>
																<Link to={subItem.url} title={subItem.label}>
																	{subItem.label}
																</Link>    
															</li>
														)
													})}
												</ul>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        )
                        
                    })}
                </ul>
			</div>
        </div>
    )

}

export default submenu