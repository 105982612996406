import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro'
import FramedSteps from '../components/FlexibleContent/FramedSteps'
import SoundCards from '../components/FlexibleContent/SoundCards'

const SoundExamples = ({ data, pageContext }) => {

  const { page, options } = data
  const {
    TPLSoundExamples,
    seo,
    template
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    footerSettings: { OPTFooterSettings },
    headerSettings: { OPTHeaderSettings }
  } = options

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-sound-examples'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLSoundExamples} />
        <Intro data={TPLSoundExamples} pageContext={pageContext} />
        <FramedSteps data={TPLSoundExamples} />
        <SoundCards data={TPLSoundExamples} />
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default SoundExamples