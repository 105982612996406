import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro'
import Icons from '../components/FlexibleContent/Icons'
import ImageText from '../components/FlexibleContent/ImageText'
import SplitText from '../components/FlexibleContent/SplitText'
import ProductsGrid from '../components/FlexibleContent/ProductsGrid'
import ShowcaseText from '../components/FlexibleContent/ShowcaseText'
import Trustpilot from '../components/FlexibleContent/Trustpilot'
import Embeddable from '../components/FlexibleContent/Embeddable'
import Form from '../components/FlexibleContent/Form'

const FlexibleContent = ({ data, pageContext }) => {

  const { page, options } = data
  const {
    CTRLPages, 
    TPLFlexibleContent,
    seo,
    template,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    forms: { OPTForms },
    authors: { OPTAuthors },
    headerSettings: { OPTHeaderSettings }
  } = options

  const { infoSections, type } = TPLFlexibleContent

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'Icons':
        return <Icons data={data} key={index} ctaList={OPTCTAButtons} />

      case 'ImageText':
        return <ImageText data={data} template={template} key={index} ctaList={OPTCTAButtons} />
      
      case 'SplitText':
        return <SplitText data={data} key={index} ctaList={OPTCTAButtons} />
      
      case 'ImageGrid':
        return <ProductsGrid data={data} key={index} ctaList={OPTCTAButtons} />

      case 'Trustpilot':
        return <Trustpilot data={data} country={OPTAudibeneSettings.country} key={index} />

      case 'Embeddable':
        return <Embeddable data={data} key={index} />

      case 'ShowcaseText':
        return <ShowcaseText data={data} key={index} ctaList={OPTCTAButtons} />

      default:
        return <p key={index}>{contentType}</p>
    }
  }

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-flexible-content'>
        <div className={`type-${type}`}>
          <Header data={OPTHeaderSettings} />
          <Hero data={TPLFlexibleContent} />
          <Intro 
            data={TPLFlexibleContent} 
            ctaList={OPTCTAButtons} 
            authorList={OPTAuthors} 
            type={type}
            pageContext={pageContext}
            pageTitle={title}
            ctaList={OPTCTAButtons}
          />

          {infoSections && infoSections.map((section, index) => {
            const contentType = section.fieldGroupName.replace('page_Tplflexiblecontent_InfoSections_', '');
            return assignComponent(contentType, section, index);
          })}
          
          <Form data={CTRLPages} formList={OPTForms} />
          <Footer data={OPTFooterSettings} />

        </div>
      </div>
      <Scripts />
    </>
  )
}

export default FlexibleContent