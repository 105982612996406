import React from "react"
import '../assets/sass/style.scss'
import Scripts from '../assets/js/scripts'

import Head from '../components/Head'
import Header from '../components/Header'
import Footer from '../components/Footer'

import Hero from '../components/FlexibleContent/Hero'
import Intro from '../components/FlexibleContent/Intro.prod'
import ProductsGrid from '../components/FlexibleContent/ProductsGrid'
import ImageText from '../components/FlexibleContent/ImageText'
import SplitText from '../components/FlexibleContent/SplitText'
import ThreeColumnBlocks from '../components/FlexibleContent/ThreeColumnBlocks'
import Form from '../components/FlexibleContent/Form'

const Manufacturer = ({ data, pageContext }) => {

  const { page, options } = data
  const { 
    CTRLPages, 
    TPLManufacturer,
    wpParent,
    seo,
    template,
    title
  } = page

  const {
    audibeneSettings: { OPTAudibeneSettings },
    ctaButtons: { OPTCTAButtons },
    footerSettings: { OPTFooterSettings },
    forms: { OPTForms },
    headerSettings: { OPTHeaderSettings }
  } = options

  const { manufacturerSections } = TPLManufacturer

  const assignComponent = (contentType, data, index) => {
    switch(contentType) {
      case 'Products':
        return <ProductsGrid data={data} key={index} ctaList={OPTCTAButtons} />

      case 'ImageText':
        return <ImageText data={data} template={template} key={index} ctaList={OPTCTAButtons} />
      
      case 'SplitText':
        return <SplitText data={data} key={index} ctaList={OPTCTAButtons} />
      
      case '3ColumnBlocks':
        return <ThreeColumnBlocks data={data} template={template} key={index} ctaList={OPTCTAButtons} />

      default:
        return <p key={index}>{contentType}</p>
    }
  }

  return (
    <>
      <Head seo={seo} data={OPTAudibeneSettings} />
      <div className='page-template-manufacturer'>
        <Header data={OPTHeaderSettings} />
        <Hero data={TPLManufacturer} wpParent={wpParent} />
        <Intro data={TPLManufacturer} ctaList={OPTCTAButtons} pageContext={pageContext} pageTitle={title} ctaList={OPTCTAButtons} />

        {manufacturerSections && manufacturerSections.map((section, index) => {
          const contentType = section.fieldGroupName.replace('page_Tplmanufacturer_ManufacturerSections_', '');
          return assignComponent(contentType, section, index);
        })}
        
        <Form data={CTRLPages} formList={OPTForms} />
        <Footer data={OPTFooterSettings} />
      </div>
      <Scripts />
    </>
  )
}

export default Manufacturer